import React from 'react'
import PropTypes from 'prop-types'

/**
 * Renders simple text input.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function TextInput (props) {
  const { form, label, desc, name, className, inputProps } = props

  return (
    <div className={className}>
      <label>{label}</label>
      <input
        type="text"
        className={form.getInputClass(name)}
        required={true}
        name={name}
        value={form.getValue(name)}
        onChange={form.onChange}
        {...inputProps}
      />
      {!!desc && (
        <small className="form-text text-muted">{desc}</small>
      )}
      {form.renderErrors(name)}
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
TextInput.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  desc: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  inputProps: PropTypes.object
}

/**
 * @file
 * Main form constants.
 */

/**
 * Main form tabs.
 *
 * @type {{}}
 */
export const FORM_TABS = {
  GENERAL: 'GENERAL',
  WRITER: 'WRITER',
  EDITOR: 'EDITOR',
  WIZARD: 'WIZARD',
  LEAD_WRITER: 'LEAD_WRITER',
  LEAD_EDITOR: 'LEAD_EDITOR',
  LEAD_QC: 'LEAD_QC',
  TRAINING: 'TRAINING',
  REVIEW: 'REVIEW'
}

/**
 * Names of main form tabs.
 *
 * @type {{}}
 */
export const FORM_TAB_NAMES = {
  [FORM_TABS.GENERAL]: 'General',
  [FORM_TABS.WRITER]: 'Writer',
  [FORM_TABS.EDITOR]: 'Editor',
  [FORM_TABS.WIZARD]: 'Client Success Wizard',
  [FORM_TABS.LEAD_WRITER]: 'Lead Writer',
  [FORM_TABS.LEAD_EDITOR]: 'Lead Editor',
  [FORM_TABS.LEAD_QC]: 'Lead QC',
  [FORM_TABS.TRAINING]: 'Training',
  [FORM_TABS.REVIEW]: 'Review',
}

/**
 * List of months.
 *
 * @type {string[]}
 */
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

/**
 * List of user roles.
 *
 * @type {Object}
 */
export const ROLES = {
  WRITER: 'WRITER',
  EDITOR: 'EDITOR',
  WIZARD: 'WIZARD',
  LEAD_WRITER: 'LEAD_WRITER',
  LEAD_EDITOR: 'LEAD_EDITOR',
  LEAD_QC: 'LEAD_QC'
}

/**
 * User role names.
 *
 * @type {{}}
 */
export const ROLE_NAMES = {
  [ROLES.WRITER]: 'Writer',
  [ROLES.EDITOR]: 'Editor',
  [ROLES.WIZARD]: 'Client Success Wizard',
  [ROLES.LEAD_WRITER]: 'Lead Writer',
  [ROLES.LEAD_EDITOR]: 'Lead Editor',
  [ROLES.LEAD_QC]: 'Lead QC',
}

/**
 * Payroll cutoff dates.
 *
 * @type {{}}
 */
export const CUTOFF_DATES = [
  {
    time: new Date('29 Jan 2021 14:00:00 PST').getTime(),
    month: 1,
    day: '1st'
  },
  {
    time: new Date('12 Feb 2021 14:00:00 PST').getTime(),
    month: 1,
    day: '15th'
  },
  {
    time: new Date('26 Feb 2021 14:00:00 PST').getTime(),
    month: 2,
    day: '1st'
  },
  {
    time: new Date('12 Mar 2021 17:00:00 PST').getTime(),
    month: 2,
    day: '15th'
  },
  {
    time: new Date('31 Mar 2021 14:00:00 PST').getTime(),
    month: 3,
    day: '1st'
  },
  {
    time: new Date('14 Apr 2021 14:00:00 PST').getTime(),
    month: 3,
    day: '15th'
  },
  {
    time: new Date('30 Apr 2021 14:00:00 PST').getTime(),
    month: 4,
    day: '1st'
  },
  {
    time: new Date('14 May 2021 14:00:00 PST').getTime(),
    month: 4,
    day: '15th'
  },
  {
    time: new Date('28 May 2021 14:00:00 PST').getTime(),
    month: 5,
    day: '1st'
  },
  {
    time: new Date('14 Jun 2021 14:00:00 PST').getTime(),
    month: 5,
    day: '15th'
  },
  {
    time: new Date('30 Jun 2021 14:00:00 PST').getTime(),
    month: 6,
    day: '1st'
  },
  {
    time: new Date('14 Jul 2021 14:00:00 PST').getTime(),
    month: 6,
    day: '15th'
  },
  {
    time: new Date('30 Jul 2021 14:00:00 PST').getTime(),
    month: 7,
    day: '1st'
  },
  {
    time: new Date('13 Aug 2021 14:00:00 PST').getTime(),
    month: 7,
    day: '15th'
  },
  {
    time: new Date('31 Aug 2021 14:00:00 PST').getTime(),
    month: 8,
    day: '1st'
  },
  {
    time: new Date('14 Sep 2021 14:00:00 PST').getTime(),
    month: 8,
    day: '15th'
  },
  {
    time: new Date('30 Sep 2021 14:00:00 PST').getTime(),
    month: 9,
    day: '1st'
  },
  {
    time: new Date('14 Oct 2021 14:00:00 PST').getTime(),
    month: 9,
    day: '15th'
  },
  {
    time: new Date('29 Oct 2021 14:00:00 PST').getTime(),
    month: 10,
    day: '1st'
  },
  {
    time: new Date('12 Nov 2021 14:00:00 PST').getTime(),
    month: 10,
    day: '15th'
  },
  {
    time: new Date('30 Nov 2021 14:00:00 PST').getTime(),
    month: 11,
    day: '1st'
  },
  {
    time: new Date('14 Dec 2021 14:00:00 PST').getTime(),
    month: 11,
    day: '15th'
  },
  {
    time: new Date('29 Dec 2021 14:00:00 PST').getTime(),
    month: 0,
    day: '1st'
  }
]

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { FORM_TABS } from 'ar/constants'

/**
 * Renders Next/Submit button.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function NextButton (props) {
  const { form, fields, tab, setTab, setTabs } = props

  const isValid = useMemo(function () {
    const values = form.values
    switch (tab) {
      case FORM_TABS.WRITER:
        if (!values['writer_type_client'] && !values['writer_type_internal'] && !values['training']) {
          return false
        }
        break
      case FORM_TABS.EDITOR:
        if (!values['editor_type_client'] && !values['editor_type_internal'] && !values['training']) {
          return false
        }
        break
      case FORM_TABS.WIZARD:
        if (!values['wizard_type_retention'] && !values['wizard_type_pre_publishing']
          && !values['wizard_type_post_publishing'] && !values['wizard_type_marketing']
          && !values['wizard_type_other'] && !values['training']) {
          return false
        }
        break
      case FORM_TABS.LEAD_WRITER:
        if (!values['lead_type_writer'] && !values['lead_type_manager']
          && !values['lead_type_qc_client'] && !values['training']) {
          return false
        }
        break
      case FORM_TABS.LEAD_EDITOR:
        if (!values['lead_type_editor'] && !values['lead_type_manager']
          && !values['lead_type_qc_client'] && !values['training']) {
          return false
        }
        break
      case FORM_TABS.LEAD_QC:
        if (!values['lead_type_writer'] && !values['lead_type_editor']
          && !values['lead_type_manager'] && !values['lead_type_qc_client']
          && !values['lead_type_qc_internal'] && !values['training']) {
          return false
        }
        break
      case FORM_TABS.TRAINING:
        if (
          !values['training_type_general_boarding'] &&
          !values['training_type_general_continuing'] &&
          !values['training_type_client_boarding'] &&
          !values['training_type_client_continuing']
        ) {
          return false
        }
        break
    }

    return form.isValid(fields)
  }, [tab, form, fields])

  /**
   * Proceeds the form.
   *
   * @type {function(): void}
   */
  const onNext = useCallback(function () {
    const training = form.getValue('training')
    const role = form.getValue('role')
    // noinspection JSIncompatibleTypesComparison
    const isReview = tab === FORM_TABS.TRAINING || tab !== FORM_TABS.GENERAL && !training

    const tabs = [FORM_TABS.GENERAL]

    if (role) {
      tabs.push(FORM_TABS[role])
    }
    if (training) {
      tabs.push(FORM_TABS.TRAINING)
    }
    // noinspection JSIncompatibleTypesComparison
    if (isReview) {
      tabs.push(FORM_TABS.REVIEW)
    }
    // noinspection JSValidateTypes
    setTabs(tabs)

    if (isReview) {
      // noinspection JSValidateTypes
      setTab(FORM_TABS.REVIEW)
    }
    else if (tab === FORM_TABS.GENERAL) {
      // noinspection JSValidateTypes
      setTab(FORM_TABS[role])
    }
    else {
      // noinspection JSValidateTypes
      setTab(FORM_TABS.TRAINING)
    }
  }, [tab, form])

  return (
    <button
      type="submit"
      disabled={!isValid || form.submitting}
      className={'btn btn-primary' + (isValid || form.submitting ? '' : ' disabled')}
      onClick={form.onValidate({ fields, callback: onNext })}
    >
      Next
    </button>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
NextButton.propTypes = {
  form: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
  setTabs: PropTypes.func.isRequired
}

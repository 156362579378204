/**
 * @file
 * API credentials.
 */

/**
 * Google constants.
 *
 * @type {Object}
 */
export const GOOGLE = {
  API_KEY: 'AIzaSyDXX20F_yhlbbqfPUL1z37pLmgE2Ha2few',
  CLIENT_ID: '980538919223-04g33lj768cvbpr1ah9jq7gdshu4fn8p.apps.googleusercontent.com',
  DISCOVERY_DOCS: [
    'https://sheets.googleapis.com/$discovery/rest?version=v4'
  ],
  SCOPES: 'https://www.googleapis.com/auth/spreadsheets.readonly',
  APP_URL: 'https://addiction-recove-1478121449008.uc.r.appspot.com/',
}

/**
 * Back end URLs.
 *
 * @type {Object}
 */
export const BACKEND = {
  ON_SUBMIT: 'https://api.addictionrecovery.com/payroll/hook/on-submit?_format=json'
}

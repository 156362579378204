import React from 'react'
import PropTypes from 'prop-types'

import { TextInput } from './TextInput'

/**
 * Renders simple integer input.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function IntegerInput (props) {
  return (
    <TextInput
      {...props}
      inputProps={{
        type: 'number',
        min: 1,
        step: 1
      }}
    />
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
IntegerInput.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
}

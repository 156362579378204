/**
 * @file
 * Form helpers.
 */

import { CUTOFF_DATES, MONTHS, ROLE_NAMES, ROLES } from 'ar/constants'
import { round2 } from 'ar/utils'

/**
 * Returns initial form values.
 *
 * @return {{}}
 */
export function getInitialValues () {
  const values = {}

  // Set available payperiod month and day.
  const now = new Date().getTime()
  for (let i = 0; i < CUTOFF_DATES.length; i++) {
    const { time, month, day } = CUTOFF_DATES[i]
    if (now < time) {
      values['paymonth'] = MONTHS[month]
      values['payday'] = day
      values['deadline'] = new Date(time).toLocaleString('en-US', {
        timeZone: 'PST',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short'
      })
      break
    }
  }

  return values
}

/**
 * Returns list of writer fields.
 *
 * @param {Object} values
 *   Form values.
 *
 * @return {[]}
 */
export function getWriterFields (values) {
  const fields = []
  const names = ['month', 'rate', 'qty']
  const types = ['client', 'internal']
  types.forEach(type => {
    if (values['writer_type_' + type]) {
      const count = values['writer_' + type + '_count'] || 0
      for (let n = 0; n < count; n++) {
        const prefix = 'writer_' + type + '_' + n + '_'
        if (type === 'client') {
          fields.push(prefix + 'name')
        }
        else {
          fields.push(prefix + 'type')
        }
        addFields(fields, prefix, names)
      }
    }
  })
  return fields
}

/**
 * Returns list of editor fields.
 *
 * @param {Object} values
 *   Form values.
 *
 * @return {[]}
 */
export function getEditorFields (values) {
  const fields = []
  const names = ['month', 'time', 'qty']
  const types = ['client', 'internal']
  types.forEach(type => {
    if (values['editor_type_' + type]) {
      const count = values['editor_' + type + '_count'] || 0
      for (let n = 0; n < count; n++) {
        const prefix = 'editor_' + type + '_' + n + '_'
        if (type === 'client') {
          fields.push(prefix + 'name')
        }
        else {
          fields.push(prefix + 'type')
        }
        addFields(fields, prefix, names, true, values)
      }
    }
  })
  return fields
}

/**
 * Returns list of wizard fields.
 *
 * @param {Object} values
 *   Form values.
 *
 * @return {[]}
 */
export function getWizardFields (values) {
  const fields = []
  const names = ['rate', 'time']
  const types = ['retention', 'pre_publishing', 'post_publishing', 'marketing', 'other']
  types.forEach(type => {
    if (values['wizard_type_' + type]) {
      const count = values['wizard_' + type + '_count'] || 0
      for (let n = 0; n < count; n++) {
        const prefix = 'wizard_' + type + '_' + n + '_'
        if (type === 'marketing' || type === 'other') {
          fields.push(prefix + 'type')
        }
        else {
          fields.push(prefix + 'name')
        }
        addFields(fields, prefix, names, true, values)
        if (type === 'pre_publishing' || type === 'post_publishing') {
          fields.push(prefix + 'qty')
        }
      }
    }
  })
  return fields
}

/**
 * Returns list of lead QC fields.
 *
 * @param {Object} values
 *   Form values.
 *
 * @return {[]}
 */
export function getLeadFields (values) {
  const fields = []
  const types = ['writer', 'editor', 'manager', 'qc_client', 'qc_internal']
  types.forEach(type => {
    if (values['lead_type_' + type]) {
      const count = values['lead_' + type + '_count'] || 0
      for (let n = 0; n < count; n++) {
        const prefix = 'lead_' + type + '_' + n + '_'
        switch (type) {
          case 'writer':
            addFields(fields, prefix, ['name', 'month', 'rate', 'qty'])
            break
          case 'editor':
            addFields(fields, prefix, ['name', 'month', 'rate', 'time', 'qty'], true, values)
            break
          case 'manager':
            addFields(fields, prefix, ['type', 'rate', 'time'])
            break
          case 'qc_client':
            addFields(fields, prefix, ['name', 'month', 'rate', 'time', 'qty'], true, values)
            if (values && values[prefix + 'overbudget']) {
              fields.push(prefix + 'overbudget_writer')
              fields.push(prefix + 'overbudget_editor')
            }
            break
          case 'qc_internal':
            addFields(fields, prefix, ['type', 'month', 'rate', 'time', 'qty'], true, values)
            if (values && values[prefix + 'overbudget']) {
              fields.push(prefix + 'overbudget_writer')
              fields.push(prefix + 'overbudget_editor')
            }
            break
        }
      }
    }
  })
  return fields
}

/**
 * Returns list of training fields.
 *
 * @param {Object} values
 *   Form values.
 *
 * @return {[]}
 */
export function getTrainingFields (values) {
  const fields = []
  const names = ['rate', 'time']
  const types = ['general_boarding', 'general_continuing', 'client_boarding', 'client_continuing']
  types.forEach(type => {
    if (values['training_type_' + type]) {
      if (type === 'general_boarding' || type === 'general_continuing') {
        const prefix = 'training_' + type + '_'
        addFields(fields, prefix, names, true, values)
      }
      else {
        const count = values['training_' + type + '_count'] || 0
        for (let n = 0; n < count; n++) {
          const prefix = 'training_' + type + '_' + n + '_'
          fields.push(prefix + 'name')
          addFields(fields, prefix, names, true, values)
        }
      }
    }
  })
  return fields
}

/**
 * Adds required field names to the list of fields.
 *
 * @param {Array} fields
 *   Array of field names.
 * @param {string} prefix
 *   Common field prefix.
 * @param {Array} names
 *   Array of field names to add (without prefixes).
 * @param {boolean} overbudget
 *   Whether to add overbudget fields.
 * @param {Object} values
 *   Form values. Required for overbudget fields.
 */
function addFields(fields, prefix, names, overbudget = false, values = null) {
  names.forEach(name => {
    fields.push(prefix + name)
  })
  if (overbudget && values && values[prefix + 'overbudget']) {
    fields.push(prefix + 'overbudget_info')
  }
}

/**
 * Builds data to be submitted.
 *
 * @param {Object} values
 *   Form values.
 *
 * @return {[]}
 */
export function getSubmitData (values) {
  const role = values['role']

  // Values to append to the datasheet.
  const data = []

  const general = [
    new Date().toString(),
    values['name'],
    values['email'],
    values['paymonth'],
    values['payday'],
    role.toLowerCase()
  ]

  switch (role) {

    // Proceed wizard values.
    case ROLES.WIZARD:
      // Handle wizard retention values.
      if (values['wizard_type_retention']) {
        for (let n = 0; n < values['wizard_retention_count']; n++) {
          data.push([
            ...general,
            values['wizard_retention_' + n + '_name'],
            values['wizard_retention_' + n + '_rate'],
            values['wizard_retention_' + n + '_time'],
            values['wizard_retention_' + n + '_overbudget_info']
          ])
        }
      }

      // Handle wizard pre-publishing values.
      if (values['wizard_type_pre_publishing']) {
        for (let n = 0; n < values['wizard_pre_publishing_count']; n++) {
          data.push([
            ...general,
            null,
            null,
            null,
            null,
            values['wizard_pre_publishing_' + n + '_name'],
            values['wizard_pre_publishing_' + n + '_rate'],
            values['wizard_pre_publishing_' + n + '_time'],
            values['wizard_pre_publishing_' + n + '_qty'],
            values['wizard_pre_publishing_' + n + '_overbudget_info']
          ])
        }
      }

      // Handle wizard post-publishing values.
      if (values['wizard_type_post_publishing']) {
        for (let n = 0; n < values['wizard_post_publishing_count']; n++) {
          data.push([
            ...general,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            values['wizard_post_publishing_' + n + '_name'],
            values['wizard_post_publishing_' + n + '_rate'],
            values['wizard_post_publishing_' + n + '_time'],
            values['wizard_post_publishing_' + n + '_qty'],
            values['wizard_post_publishing_' + n + '_overbudget_info']
          ])
        }
      }

      // Handle wizard marketing values.
      if (values['wizard_type_marketing']) {
        for (let n = 0; n < values['wizard_marketing_count']; n++) {
          data.push([
            ...general,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            values['wizard_marketing_' + n + '_type'],
            values['wizard_marketing_' + n + '_rate'],
            values['wizard_marketing_' + n + '_time'],
            values['wizard_marketing_' + n + '_overbudget_info']
          ])
        }
      }

      // Handle wizard other values.
      if (values['wizard_type_other']) {
        for (let n = 0; n < values['wizard_other_count']; n++) {
          data.push([
            ...general,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            values['wizard_other_' + n + '_type'],
            values['wizard_other_' + n + '_rate'],
            values['wizard_other_' + n + '_time'],
            values['wizard_other_' + n + '_overbudget_info']
          ])
        }
      }

      // Add training values.
      if (values['training']) {
        // Handle general boarding training values.
        if (values['training_type_general_boarding']) {
          data.push([
            ...general,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            values['training_general_boarding_rate'],
            values['training_general_boarding_time'],
            values['training_general_boarding_overbudget_info']
          ])
        }

        // Handle general continuing training values.
        if (values['training_type_general_continuing']) {
          data.push([
            ...general,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            values['training_general_continuing_rate'],
            values['training_general_continuing_time'],
            values['training_general_continuing_overbudget_info']
          ])
        }

        // Handle client boarding training values.
        if (values['training_type_client_boarding']) {
          for (let n = 0; n < values['training_client_boarding_count']; n++) {
            data.push([
              ...general,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              values['training_client_boarding_' + n + '_name'],
              values['training_client_boarding_' + n + '_rate'],
              values['training_client_boarding_' + n + '_time'],
              values['training_client_boarding_' + n + '_overbudget_info']
            ])
          }
        }

        // Handle client continuing training values.
        if (values['training_type_client_continuing']) {
          for (let n = 0; n < values['training_client_continuing_count']; n++) {
            data.push([
              ...general,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              values['training_client_continuing_' + n + '_name'],
              values['training_client_continuing_' + n + '_rate'],
              values['training_client_continuing_' + n + '_time'],
              values['training_client_continuing_' + n + '_overbudget_info']
            ])
          }
        }
      }
      break

    // Proceed writer/editor values.
    case ROLES.WRITER:
    case ROLES.EDITOR:
      // Proceed writer data.
      if (role === ROLES.WRITER) {
        // Handle client writer values.
        if (values['writer_type_client']) {
          for (let n = 0; n < values['writer_client_count']; n++) {
            data.push([
              ...general,
              values['writer_client_' + n + '_name'],
              values['writer_client_' + n + '_month'],
              values['writer_client_' + n + '_rate'],
              values['writer_client_' + n + '_qty'],
            ])
          }
        }

        // Handle internal writer values.
        if (values['writer_type_internal']) {
          for (let n = 0; n < values['writer_internal_count']; n++) {
            data.push([
              ...general,
              values['writer_internal_' + n + '_type'],
              values['writer_internal_' + n + '_month'],
              values['writer_internal_' + n + '_rate'],
              values['writer_internal_' + n + '_qty'],
            ])
          }
        }
      }

      if (role === ROLES.EDITOR) {
        // Handle client editor values.
        if (values['editor_type_client']) {
          for (let n = 0; n < values['editor_client_count']; n++) {
            let overbudget = values['editor_client_' + n + '_overbudget_info']
            if (values['editor_client_' + n + '_overbudget_writer']) {
              overbudget += ' | writer: ' + values['editor_client_' + n + '_overbudget_writer']
            }
            data.push([
              ...general,
              null,
              null,
              null,
              null,
              values['editor_client_' + n + '_name'],
              values['editor_client_' + n + '_month'],
              values['editor_client_' + n + '_qty'],
              values['editor_client_' + n + '_time'],
              overbudget
            ])
          }
        }

        // Handle internal editor values.
        if (values['editor_type_internal']) {
          for (let n = 0; n < values['editor_internal_count']; n++) {
            let overbudget = values['editor_internal_' + n + '_overbudget_info']
            if (values['editor_internal_' + n + '_overbudget_writer']) {
              overbudget += ' | writer: ' + values['editor_internal_' + n + '_overbudget_writer']
            }
            data.push([
              ...general,
              null,
              null,
              null,
              null,
              values['editor_internal_' + n + '_type'],
              values['editor_internal_' + n + '_month'],
              values['editor_internal_' + n + '_qty'],
              values['editor_internal_' + n + '_time'],
              overbudget
            ])
          }
        }
      }

      // Add training values (common for writer and editor).
      if (values['training']) {
        // Handle general boarding training values.
        if (values['training_type_general_boarding']) {
          data.push([
            ...general,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            values['training_general_boarding_rate'],
            values['training_general_boarding_time'],
            values['training_general_boarding_overbudget_info']
          ])
        }

        // Handle general continuing training values.
        if (values['training_type_general_continuing']) {
          data.push([
            ...general,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            values['training_general_continuing_rate'],
            values['training_general_continuing_time'],
            values['training_general_continuing_overbudget_info']
          ])
        }

        // Handle client boarding training values.
        if (values['training_type_client_boarding']) {
          for (let n = 0; n < values['training_client_boarding_count']; n++) {
            data.push([
              ...general,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              values['training_client_boarding_' + n + '_name'],
              values['training_client_boarding_' + n + '_rate'],
              values['training_client_boarding_' + n + '_time'],
              values['training_client_boarding_' + n + '_overbudget_info']
            ])
          }
        }

        // Handle client continuing training values.
        if (values['training_type_client_continuing']) {
          for (let n = 0; n < values['training_client_continuing_count']; n++) {
            data.push([
              ...general,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              values['training_client_continuing_' + n + '_name'],
              values['training_client_continuing_' + n + '_rate'],
              values['training_client_continuing_' + n + '_time'],
              values['training_client_continuing_' + n + '_overbudget_info']
            ])
          }
        }
      }
      break

    case ROLES.LEAD_WRITER:
    case ROLES.LEAD_EDITOR:
    case ROLES.LEAD_QC:
      // Lead writer specific data.
      if (role !== ROLES.LEAD_EDITOR) {
        if (values['lead_type_writer']) {
          for (let n = 0; n < values['lead_writer_count']; n++) {
            data.push([
              ...general,
              values['lead_writer_' + n + '_name'],
              values['lead_writer_' + n + '_month'],
              values['lead_writer_' + n + '_rate'],
              values['lead_writer_' + n + '_qty'],
            ])
          }
        }
      }

      // Managerial duties.
      if (values['lead_type_manager']) {
        for (let n = 0; n < values['lead_manager_count']; n++) {
          data.push([
            ...general,
            null,
            null,
            null,
            null,
            values['lead_manager_' + n + '_type'],
            values['lead_manager_' + n + '_rate'],
            values['lead_manager_' + n + '_time'],
          ])
        }
      }

      // Client related pre publishing QC.
      if (values['lead_type_qc_client']) {
        for (let n = 0; n < values['lead_qc_client_count']; n++) {
          data.push([
            ...general,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            values['lead_qc_client_' + n + '_name'],
            values['lead_qc_client_' + n + '_rate'],
            values['lead_qc_client_' + n + '_month'],
            values['lead_qc_client_' + n + '_time'],
            values['lead_qc_client_' + n + '_qty'],
            values['lead_qc_client_' + n + '_overbudget_info'],
            values['lead_qc_client_' + n + '_overbudget_writer'],
            values['lead_qc_client_' + n + '_overbudget_editor'],
          ])
        }
      }

      // Internal pre publishing QC.
      if (role === ROLES.LEAD_QC) {
        if (values['lead_type_qc_internal']) {
          for (let n = 0; n < values['lead_qc_internal_count']; n++) {
            data.push([
              ...general,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              values['lead_qc_internal_' + n + '_type'],
              values['lead_qc_internal_' + n + '_rate'],
              values['lead_qc_internal_' + n + '_month'],
              values['lead_qc_internal_' + n + '_time'],
              values['lead_qc_internal_' + n + '_qty'],
              values['lead_qc_internal_' + n + '_overbudget_info'],
              values['lead_qc_internal_' + n + '_overbudget_writer'],
              values['lead_qc_internal_' + n + '_overbudget_editor'],
            ])
          }
        }
      }

      // Lead editor specific data.
      if (role !== ROLES.LEAD_WRITER) {
        if (values['lead_type_editor']) {
          for (let n = 0; n < values['lead_editor_count']; n++) {
            let overbudget = values['lead_editor_' + n + '_overbudget_info']
            if (values['lead_editor_' + n + '_overbudget_writer']) {
              overbudget += ' | writer: ' + values['lead_editor_' + n + '_overbudget_writer']
            }
            data.push([
              ...general,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              values['lead_editor_' + n + '_name'],
              values['lead_editor_' + n + '_month'],
              values['lead_editor_' + n + '_rate'],
              values['lead_editor_' + n + '_qty'],
              values['lead_editor_' + n + '_time'],
              overbudget
            ])
          }
        }
      }

      // Add training values (common for lead writer/editor/QC).
      if (values['training']) {
        // Handle general boarding training values.
        if (values['training_type_general_boarding']) {
          data.push([
            ...general,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            values['training_general_boarding_rate'],
            values['training_general_boarding_time'],
            values['training_general_boarding_overbudget_info']
          ])
        }

        // Handle general continuing training values.
        if (values['training_type_general_continuing']) {
          data.push([
            ...general,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            values['training_general_continuing_rate'],
            values['training_general_continuing_time'],
            values['training_general_continuing_overbudget_info']
          ])
        }

        // Handle client boarding training values.
        if (values['training_type_client_boarding']) {
          for (let n = 0; n < values['training_client_boarding_count']; n++) {
            data.push([
              ...general,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              values['training_client_boarding_' + n + '_name'],
              values['training_client_boarding_' + n + '_rate'],
              values['training_client_boarding_' + n + '_time'],
              values['training_client_boarding_' + n + '_overbudget_info']
            ])
          }
        }

        // Handle client continuing training values.
        if (values['training_type_client_continuing']) {
          for (let n = 0; n < values['training_client_continuing_count']; n++) {
            data.push([
              ...general,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              null,
              values['training_client_continuing_' + n + '_name'],
              values['training_client_continuing_' + n + '_rate'],
              values['training_client_continuing_' + n + '_time'],
              values['training_client_continuing_' + n + '_overbudget_info']
            ])
          }
        }
      }

      break
  }

  return data
}

/**
 * Returns user entered data for review.
 *
 * @param {Object} values
 *   Form submitted values.
 *
 * @return {[]}
 */
export function getReviewData (values) {
  // Data to review.
  const items = []
  // Total amount.
  let total = 0

  // General data.
  items.push({
    label: 'General info',
    cols: ['Name', 'Email', 'Payperiod month', 'Payperiod day', 'Role'],
    rows: [[values['name'], values['email'], values['paymonth'], values['payday'], ROLE_NAMES[values['role']]]
    ]
  })

  // Proceed wizard values.
  if (values['role'] === ROLES.WIZARD) {

    // Wizard retention data.
    if (values['wizard_type_retention']) {
      const wizardRetention = {
        label: 'Client Retention',
        cols: ['Client', 'Rate', 'Time', 'Overbudget', 'Total'],
        rows: []
      }
      for (let n = 0; n < values['wizard_retention_count']; n++) {
        const t = round2(values['wizard_retention_' + n + '_rate'] * values['wizard_retention_' + n + '_time'] / 60)
        total += t
        wizardRetention.rows.push([
          values['wizard_retention_' + n + '_name'],
          values['wizard_retention_' + n + '_rate'],
          values['wizard_retention_' + n + '_time'],
          values['wizard_retention_' + n + '_overbudget_info'],
          '$' + t
        ])
      }
      items.push(wizardRetention)
    }

    // Wizard pre-publishing data.
    if (values['wizard_type_pre_publishing']) {
      const wizardPrePublishing = {
        label: 'Pre Publishing QC',
        cols: ['Client', 'Rate', 'Time', 'Quantity', 'Overbudget', 'Total'],
        rows: []
      }
      for (let n = 0; n < values['wizard_pre_publishing_count']; n++) {
        const t = round2(values['wizard_pre_publishing_' + n + '_rate'] * values['wizard_pre_publishing_' + n + '_time'] * values['wizard_pre_publishing_' + n + '_qty'] / 60)
        total += t
        wizardPrePublishing.rows.push([
          values['wizard_pre_publishing_' + n + '_name'],
          values['wizard_pre_publishing_' + n + '_rate'],
          values['wizard_pre_publishing_' + n + '_time'],
          values['wizard_pre_publishing_' + n + '_qty'],
          values['wizard_pre_publishing_' + n + '_overbudget_info'],
          '$' + t
        ])
      }
      items.push(wizardPrePublishing)
    }

    // Wizard post-publishing data.
    if (values['wizard_type_post_publishing']) {
      const wizardPostPublishing = {
        label: 'Post Publishing QC',
        cols: ['Client', 'Rate', 'Time', 'Quantity', 'Overbudget', 'Total'],
        rows: []
      }
      for (let n = 0; n < values['wizard_post_publishing_count']; n++) {
        const t = round2(values['wizard_post_publishing_' + n + '_rate'] * values['wizard_post_publishing_' + n + '_time'] * values['wizard_post_publishing_' + n + '_qty'] / 60)
        total += t
        wizardPostPublishing.rows.push([
          values['wizard_post_publishing_' + n + '_name'],
          values['wizard_post_publishing_' + n + '_rate'],
          values['wizard_post_publishing_' + n + '_time'],
          values['wizard_post_publishing_' + n + '_qty'],
          values['wizard_post_publishing_' + n + '_overbudget_info'],
          '$' + t
        ])
      }
      items.push(wizardPostPublishing)
    }

    // Wizard marketing data.
    if (values['wizard_type_marketing']) {
      const wizardMarketing = {
        label: 'Marketing Management',
        cols: ['Type', 'Rate', 'Time', 'Overbudget', 'Total'],
        rows: []
      }
      for (let n = 0; n < values['wizard_marketing_count']; n++) {
        const t = round2(values['wizard_marketing_' + n + '_rate'] * values['wizard_marketing_' + n + '_time'] / 60)
        total += t
        wizardMarketing.rows.push([
          values['wizard_marketing_' + n + '_type'],
          values['wizard_marketing_' + n + '_rate'],
          values['wizard_marketing_' + n + '_time'],
          values['wizard_marketing_' + n + '_overbudget_info'],
          '$' + t
        ])
      }
      items.push(wizardMarketing)
    }

    // Wizard other services data.
    if (values['wizard_type_other']) {
      const wizardOther = {
        label: 'Other Services',
        cols: ['Type', 'Rate', 'Time', 'Overbudget', 'Total'],
        rows: []
      }
      for (let n = 0; n < values['wizard_other_count']; n++) {
        const t = round2(values['wizard_other_' + n + '_rate'] * values['wizard_other_' + n + '_time'] / 60)
        total += t
        wizardOther.rows.push([
          values['wizard_other_' + n + '_type'],
          values['wizard_other_' + n + '_rate'],
          values['wizard_other_' + n + '_time'],
          values['wizard_other_' + n + '_overbudget_info'],
          '$' + t
        ])
      }
      items.push(wizardOther)
    }
  }
  // Proceed writer values.
  else if (values['role'] === ROLES.WRITER) {
    // Client writer data.
    if (values['writer_type_client']) {
      const writerClient = {
        label: 'Client writer',
        cols: ['Client', 'Month', 'Rate', 'Articles', 'Total'],
        rows: []
      }
      for (let n = 0; n < values['writer_client_count']; n++) {
        const t = values['writer_client_' + n + '_rate'] * values['writer_client_' + n + '_qty']
        total += t
        writerClient.rows.push([
          values['writer_client_' + n + '_name'],
          values['writer_client_' + n + '_month'],
          values['writer_client_' + n + '_rate'],
          values['writer_client_' + n + '_qty'],
          '$' + t
        ])
      }
      items.push(writerClient)
    }

    // Internal writer data.
    if (values['writer_type_internal']) {
      const writerInternal = {
        label: 'Internal writer',
        cols: ['Type', 'Month', 'Rate', 'Articles', 'Total'],
        rows: []
      }
      for (let n = 0; n < values['writer_internal_count']; n++) {
        const t = values['writer_internal_' + n + '_rate'] * values['writer_internal_' + n + '_qty']
        total += t
        writerInternal.rows.push([
          values['writer_internal_' + n + '_type'],
          values['writer_internal_' + n + '_month'],
          values['writer_internal_' + n + '_rate'],
          values['writer_internal_' + n + '_qty'],
          '$' + t
        ])
      }
      items.push(writerInternal)
    }
  }
  // Proceed editor values.
  else if (values['role'] === ROLES.EDITOR) {
    // Client editor data.
    if (values['editor_type_client']) {
      const editorClient = {
        label: 'Client editor',
        cols: ['Client', 'Month', 'Rate', 'Articles', 'Time', 'Overbudget', 'Total'],
        rows: []
      }
      for (let n = 0; n < values['editor_client_count']; n++) {
        const t = round2(20 * values['editor_client_' + n + '_qty'] * values['editor_client_' + n + '_time'] / 60)
        total += t
        let overbudget = values['editor_client_' + n + '_overbudget_info']
        if (values['editor_client_' + n + '_overbudget_writer']) {
          overbudget += ' | writer: ' + values['editor_client_' + n + '_overbudget_writer']
        }
        editorClient.rows.push([
          values['editor_client_' + n + '_name'],
          values['editor_client_' + n + '_month'],
          20,
          values['editor_client_' + n + '_qty'],
          values['editor_client_' + n + '_time'],
          overbudget,
          '$' + t
        ])
      }
      items.push(editorClient)
    }

    // Internal editor data.
    if (values['editor_type_internal']) {
      const editorInternal = {
        label: 'Internal editor',
        cols: ['Type', 'Month', 'Rate', 'Articles', 'Time', 'Overbudget', 'Total'],
        rows: []
      }
      for (let n = 0; n < values['editor_internal_count']; n++) {
        const t = round2(20 * values['editor_internal_' + n + '_qty'] * values['editor_internal_' + n + '_time'] / 60)
        total += t
        let overbudget = values['editor_internal_' + n + '_overbudget_info']
        if (values['editor_internal_' + n + '_overbudget_writer']) {
          overbudget += ' | writer: ' + values['editor_internal_' + n + '_overbudget_writer']
        }
        editorInternal.rows.push([
          values['editor_internal_' + n + '_type'],
          values['editor_internal_' + n + '_month'],
          20,
          values['editor_internal_' + n + '_qty'],
          values['editor_internal_' + n + '_time'],
          overbudget,
          '$' + t
        ])
      }
      items.push(editorInternal)
    }
  }
  // Proceed lead writer/editor/QC values.
  else {
    if (values['role'] !== ROLES.LEAD_EDITOR) {
      // Client writer data.
      if (values['lead_type_writer']) {
        const data = {
          label: 'Client writer',
          cols: ['Client', 'Month', 'Rate', 'Articles', 'Total'],
          rows: []
        }
        for (let n = 0; n < values['lead_writer_count']; n++) {
          const t = values['lead_writer_' + n + '_rate'] * values['lead_writer_' + n + '_qty']
          total += t
          data.rows.push([
            values['lead_writer_' + n + '_name'],
            values['lead_writer_' + n + '_month'],
            values['lead_writer_' + n + '_rate'],
            values['lead_writer_' + n + '_qty'],
            '$' + t
          ])
        }
        items.push(data)
      }
    }

    if (values['role'] !== ROLES.LEAD_WRITER) {
      // Client editor data.
      if (values['lead_type_editor']) {
        const data = {
          label: 'Client editor',
          cols: ['Client', 'Month', 'Rate', 'Time', 'Articles', 'Overbudget', 'Overbudget Writer', 'Total'],
          rows: []
        }
        for (let n = 0; n < values['lead_editor_count']; n++) {
          const t = round2(values['lead_editor_' + n + '_rate'] * values['lead_editor_' + n + '_time'] * values['lead_editor_' + n + '_qty'] / 60)
          total += t
          data.rows.push([
            values['lead_editor_' + n + '_name'],
            values['lead_editor_' + n + '_month'],
            values['lead_editor_' + n + '_rate'],
            values['lead_editor_' + n + '_time'],
            values['lead_editor_' + n + '_qty'],
            values['lead_editor_' + n + '_overbudget_info'],
            values['lead_editor_' + n + '_overbudget_writer'],
            '$' + t
          ])
        }
        items.push(data)
      }
    }

    // Lead managerial duties data.
    if (values['lead_type_manager']) {
      const data = {
        label: 'Managerial Duties',
        cols: ['Type', 'Rate', 'Time', 'Total'],
        rows: []
      }
      for (let n = 0; n < values['lead_manager_count']; n++) {
        const t = round2(values['lead_manager_' + n + '_rate'] * values['lead_manager_' + n + '_time'] / 60)
        total += t
        data.rows.push([
          values['lead_manager_' + n + '_type'],
          values['lead_manager_' + n + '_rate'],
          values['lead_manager_' + n + '_time'],
          '$' + t
        ])
      }
      items.push(data)
    }

    // Lead QC client related pre publishing QC data.
    if (values['lead_type_qc_client']) {
      const data = {
        label: 'Pre Publishing Client QC',
        cols: ['Client', 'Month', 'Rate', 'Time', 'Articles', 'Overbudget', 'Overbudget Writer', 'Overbudget Editor', 'Total'],
        rows: []
      }
      for (let n = 0; n < values['lead_qc_client_count']; n++) {
        const t = round2(values['lead_qc_client_' + n + '_rate'] * values['lead_qc_client_' + n + '_time'] * values['lead_qc_client_' + n + '_qty'] / 60)
        total += t
        data.rows.push([
          values['lead_qc_client_' + n + '_name'],
          values['lead_qc_client_' + n + '_month'],
          values['lead_qc_client_' + n + '_rate'],
          values['lead_qc_client_' + n + '_time'],
          values['lead_qc_client_' + n + '_qty'],
          values['lead_qc_client_' + n + '_overbudget_info'],
          values['lead_qc_client_' + n + '_overbudget_writer'],
          values['lead_qc_client_' + n + '_overbudget_editor'],
          '$' + t
        ])
      }
      items.push(data)
    }

    if (values['role'] === ROLES.LEAD_QC) {
      // Lead QC internal pre publishing QC data.
      if (values['lead_type_qc_internal']) {
        const data = {
          label: 'Pre Publishing Internal QC',
          cols: ['Type', 'Month', 'Rate', 'Time', 'Articles', 'Total'],
          rows: []
        }
        for (let n = 0; n < values['lead_qc_internal_count']; n++) {
          const t = round2(values['lead_qc_internal_' + n + '_rate'] * values['lead_qc_internal_' + n + '_time'] * values['lead_qc_internal_' + n + '_qty'] / 60)
          total += t
          data.rows.push([
            values['lead_qc_internal_' + n + '_type'],
            values['lead_qc_internal_' + n + '_month'],
            values['lead_qc_internal_' + n + '_rate'],
            values['lead_qc_internal_' + n + '_time'],
            values['lead_qc_internal_' + n + '_qty'],
            '$' + t
          ])
        }
        items.push(data)
      }
    }
  }

  // Training data.
  if (values['training']) {

    // General boarding training data.
    if (values['training_type_general_boarding']) {
      const t = round2(values['training_general_boarding_rate'] * values['training_general_boarding_time'] / 60)
      total += t
      items.push({
        label: 'General Training On Boarding',
        cols: ['Rate', 'Time', 'Total'],
        rows: [[
          values['training_general_boarding_rate'],
          values['training_general_boarding_time'],
          '$' + t
        ]]
      })
    }

    // General continuing training data.
    if (values['training_type_general_continuing']) {
      const t = round2(values['training_general_continuing_rate'] * values['training_general_continuing_time'] / 60)
      total += t
      items.push({
        label: 'General Continuing Training',
        cols: ['Rate', 'Time', 'Total'],
        rows: [[
          values['training_general_continuing_rate'],
          values['training_general_continuing_time'],
          '$' + t
        ]]
      })
    }

    // Client boarding training data.
    if (values['training_type_client_boarding']) {
      const trainingClientBoarding = {
        label: 'Client Training On Boarding',
        cols: ['Client', 'Rate', 'Time', 'Total'],
        rows: []
      }
      for (let n = 0; n < values['training_client_boarding_count']; n++) {
        const t = round2(values['training_client_boarding_' + n + '_rate'] * values['training_client_boarding_' + n + '_time'] / 60)
        total += t
        trainingClientBoarding.rows.push([
          values['training_client_boarding_' + n + '_name'],
          values['training_client_boarding_' + n + '_rate'],
          values['training_client_boarding_' + n + '_time'],
          '$' + t
        ])
      }
      items.push(trainingClientBoarding)
    }

    // Client continuing training data.
    if (values['training_type_client_continuing']) {
      const trainingClientContinuing = {
        label: 'Client Continuing Training',
        cols: ['Client', 'Rate', 'Time', 'Total'],
        rows: []
      }
      for (let n = 0; n < values['training_client_continuing_count']; n++) {
        const t = round2(values['training_client_continuing_' + n + '_rate'] * values['training_client_continuing_' + n + '_time'] / 60)
        total += t
        trainingClientContinuing.rows.push([
          values['training_client_continuing_' + n + '_name'],
          values['training_client_continuing_' + n + '_rate'],
          values['training_client_continuing_' + n + '_time'],
          '$' + t
        ])
      }
      items.push(trainingClientContinuing)
    }
  }

  return {
    items,
    total: round2(total)
  }
}

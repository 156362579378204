import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'

import { Form } from 'ar/components'
import { googleInit } from 'ar/utils'

// Initialize Google.
googleInit()

ReactDOM.render(
  <App/>,
  document.getElementById('app-root')
)

/**
 * Renders the app content.
 *
 * @return {*}
 * @constructor
 */
function App () {
  return (
    <main className="py-5">
      <div className="container">
        <Form/>
      </div>
    </main>
  )
}

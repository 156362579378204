/**
 * @file
 * Google spreadsheet constants.
 */

/**
 * Main spreadsheet containing data to fill form with.
 *
 * @type {Object}
 */
export const DATA_SHEET = {
  ID1: '1sMFj1UZ1VHqdQ5eFIoHE0qGhHnE1vrLVyi_hQcojsvA',
  CLIENT_RANGE1: 'Client List & Package!A2:A',
  CLIENT_RANGE2: 'Client List Training & Client Sucess Wizards !A2:A',
  APPEND_RANGE1: 'Submission Data (writer/editor)!A3:AC3',
  APPEND_RANGE_LEAD: 'Submission Data (leads)!A3:AO3',
  USERS_RANGE: 'Master sheet Paypal emails!A3:C',
  ID2: '15L_RfHw-72xgeh0rX1RyWoQJA-IqqAXP-X8RhLMp6-0',
  APPEND_RANGE2: 'Submission Data!A2:AN2'
}

import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { FORM_TABS } from 'ar/constants'
import { getEditorFields } from 'ar/utils'
import {
  Checkbox,
  ClientSelect1,
  IntegerInput,
  MonthSelect,
  OverbudgetFields,
  Select
} from 'ar/components/fields'

import { NextButton } from './NextButton'

/**
 * Renders Editor form section.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function EditorForm (props) {
  const { form, clients, users, setTab, setTabs } = props

  const isClient = !!form.getValue('editor_type_client')
  const isInternal = !!form.getValue('editor_type_internal')

  const clientCount = form.getValue('editor_client_count', 0)
  const internalCount = form.getValue('editor_internal_count', 0)

  const clientRange = [...Array(clientCount).keys()]
  const internalRange = [...Array(internalCount).keys()]

  // List of fields on the pane.
  const fields = useMemo(function () {
    return getEditorFields(form.values)
  }, [form.values])

  /**
   * Handles "Add item" button clicks for client editors.
   *
   * @type {function(*): void}
   */
  const onClientAddClick = useCallback(function (e) {
    form.setValue('editor_client_count', clientCount + 1)
    e.preventDefault()
  }, [clientCount])

  /**
   * Handles "Remove" button clicks for client editors.
   *
   * @type {function(*): void}
   */
  const onClientRemoveClick = useCallback(function (e) {
    if (clientCount > 1) {
      form.setValue('editor_client_count', clientCount - 1)
    }
    e.preventDefault()
  }, [clientCount])

  /**
   * Handles "Add item" button clicks for internal editors.
   *
   * @type {function(*): void}
   */
  const onInternalAddClick = useCallback(function (e) {
    form.setValue('editor_internal_count', internalCount + 1)
    e.preventDefault()
  }, [internalCount])

  /**
   * Handles "Remove" button clicks for internal editors.
   *
   * @type {function(*): void}
   */
  const onInternalRemoveClick = useCallback(function (e) {
    if (clientCount > 1) {
      form.setValue('editor_internal_count', internalCount - 1)
    }
    e.preventDefault()
  }, [internalCount])

  /**
   * Handles "Prev" button clicks.
   *
   * @type {function(*): void}
   */
  const onPrevClick = useCallback(function (e) {
    // noinspection JSValidateTypes
    setTab(FORM_TABS.GENERAL)
    e.preventDefault()
  }, [])

  // Synchronize client count with checkbox.
  useEffect(function () {
    if (isClient) {
      if (!form.values['editor_client_count']) {
        form.setValue('editor_client_count', 1)
      }
    }
    else {
      form.setValue('editor_client_count', 0)
    }
  }, [isClient])

  // Synchronize internal count with checkbox.
  useEffect(function () {
    if (isInternal) {
      if (!form.values['editor_internal_count']) {
        form.setValue('editor_internal_count', 1)
      }
    }
    else {
      form.setValue('editor_internal_count', 0)
    }
  }, [isInternal])

  // noinspection JSUnresolvedFunction,JSUnresolvedVariable
  return (
    <div>
      <div className="form-row">
        <Checkbox
          form={form}
          label="Did the editor edit client related articles?"
          name="editor_type_client"
          className="form-group col-md-6"
        />
        <Checkbox
          form={form}
          label="Did the editor edit internal articles?"
          name="editor_type_internal"
          className="form-group col-md-6"
        />
      </div>
      {isClient && (
        <div className="mb-5">
          <h4>Client editor</h4>
          {clientRange.map(n => (
            <div key={n} className="card w-100 mb-3">
              <div className="card-body">
                <div className="form-row">
                  <ClientSelect1
                    form={form}
                    clients={clients}
                    label="Select client list & client package"
                    name={'editor_client_' + n + '_name'}
                    className="form-group col-md-6"
                  />
                  <MonthSelect
                    form={form}
                    name={'editor_client_' + n + '_month'}
                    label="Month the Article is written for"
                    className="form-group col-md-6"
                  />
                </div>
                <NumericFields
                  form={form}
                  type="client"
                  n={n}
                />
                <OverbudgetFields
                  form={form}
                  prefix={'editor_client_' + n + '_'}
                  checkboxLabel="Over 30 minutes per article?"
                  textareaLabel="Explain in detail why over time spent"
                  writers={users.writers}
                />
                {n > 0 && n === clientCount - 1 && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-danger"
                    onClick={onClientRemoveClick}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
          <button
            type="submit"
            className="btn btn-sm btn-info"
            onClick={onClientAddClick}
          >
            Add item
          </button>
        </div>
      )}
      {isInternal && (
        <div className="mb-5">
          <h4>Internal editor</h4>
          {internalRange.map(n => (
            <div key={n} className="card w-100 mb-3">
              <div className="card-body">
                <div className="form-row">
                  <Select
                    form={form}
                    name={'editor_internal_' + n + '_type'}
                    label="What type of articles were written?"
                    className="form-group col-md-6"
                    options={{
                      'Addiction Recovery': 'Addiction Recovery',
                      'Senior Care': 'Senior Care'
                    }}
                  />
                  <MonthSelect
                    form={form}
                    name={'editor_internal_' + n + '_month'}
                    label="Month the Article is written for"
                    className="form-group col-md-6"
                  />
                </div>
                <NumericFields
                  form={form}
                  type="internal"
                  n={n}
                />
                <OverbudgetFields
                  form={form}
                  prefix={'editor_internal_' + n + '_'}
                  checkboxLabel="Over 30 minutes per article?"
                  textareaLabel="Explain in detail why over time spent"
                  writers={users.writers}
                />
                {n > 0 && n === internalCount - 1 && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-danger"
                    onClick={onInternalRemoveClick}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
          <button
            type="submit"
            className="btn btn-sm btn-info"
            onClick={onInternalAddClick}
          >
            Add item
          </button>
        </div>
      )}
      <Checkbox
        form={form}
        label="Did editor have any training?"
        name="training"
        className="form-group"
      />
      <div className="d-flex justify-content-between">
        <button
          type="submit"
          className="btn btn-secondary"
          onClick={onPrevClick}
        >
          Back
        </button>
        <NextButton
          form={form}
          fields={fields}
          tab={FORM_TABS.EDITOR}
          setTab={setTab}
          setTabs={setTabs}
        />
      </div>
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
EditorForm.propTypes = {
  form: PropTypes.object.isRequired,
  clients: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  setTab: PropTypes.func.isRequired,
  setTabs: PropTypes.func.isRequired
}

/**
 * Renders numeric fields.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function NumericFields (props) {
  const { form, type, n } = props
  const prefix = 'editor_' + type + '_' + n + '_'
  return (
    <div className="form-row">
      <IntegerInput
        form={form}
        name={prefix + 'time'}
        label="Edit time spent in minutes on each article"
        className="form-group col-md-6"
      />
      <IntegerInput
        form={form}
        name={prefix + 'qty'}
        label="Qty of Articles edited"
        className="form-group col-md-6"
      />
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
NumericFields.propTypes = {
  form: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  n: PropTypes.number.isRequired
}

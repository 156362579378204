import React from 'react'
import PropTypes from 'prop-types'

/**
 * Renders simple checkbox.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function Checkbox (props) {
  const { form, label, name, className } = props

  return (
    <div className={className}>
      <div className="form-check">
        <label className="form-check-label">
          <input
            type="checkbox"
            className={form.getInputClass(name, true)}
            name={name}
            value={true}
            checked={!!form.getValue(name)}
            onChange={form.onChange}
          />
          {label}
          {form.renderErrors(name)}
        </label>
      </div>
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
Checkbox.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
}

import React from 'react'
import PropTypes from 'prop-types'

import { FORM_TABS, ROLE_NAMES } from 'ar/constants'
import { Select, TextInput } from 'ar/components/fields'

import { NextButton } from './NextButton'

/**
 * Renders General form section.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function GeneralForm (props) {
  const { form, setTab, setTabs } = props

  // noinspection JSUnresolvedFunction,JSUnresolvedVariable
  return (
    <div>
      <div className="form-row">
        <TextInput
          form={form}
          name="name"
          label="Name"
          className="form-group col-md-4"
        />
        <TextInput
          form={form}
          name="email"
          label="Paypal email address"
          className="form-group col-md-4"
          inputProps={{
            type: 'email'
          }}
        />
        <TextInput
          form={form}
          name="email_alt"
          label="Alternate email address"
          className="form-group col-md-4"
          inputProps={{
            type: 'email'
          }}
        />
      </div>
      <div className="form-row">
        <TextInput
          form={form}
          name="paymonth"
          label="Current payperiod month"
          desc={'Submission deadline is ' + form.values['deadline']}
          className="form-group col-md-4"
          inputProps={{
            readOnly: true
          }}
        />
        <TextInput
          form={form}
          name="payday"
          label="Payperiod day"
          className="form-group col-md-4"
          inputProps={{
            readOnly: true
          }}
        />
        <Select
          form={form}
          label="Role"
          name="role"
          className="form-group col-md-4"
          options={ROLE_NAMES}
        />
      </div>
      <div className="d-flex justify-content-end">
        <NextButton
          form={form}
          fields={FIELDS}
          tab={FORM_TABS.GENERAL}
          setTab={setTab}
          setTabs={setTabs}
        />
      </div>
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
GeneralForm.propTypes = {
  form: PropTypes.object.isRequired,
  setTab: PropTypes.func.isRequired,
  setTabs: PropTypes.func.isRequired
}

/**
 * List of field on this pane.
 *
 * @type {string[]}
 */
const FIELDS = [
  'name',
  'email',
  'paymonth',
  'payday',
  'role'
]

/**
 * @file
 * Google helpers.
 */

import { GOOGLE } from 'ar/constants'

/**
 * Whether Google SDK was initialized.
 *
 * @type {boolean}
 */
let initialized = false

/**
 * Stack of Google SDK init callbacks.
 *
 * @type {Array}
 */
const initCallbacks = []

/**
 * Registers new Google SDK init callback.
 *
 * @param {Function} callback
 *   Google SDK initialization callback.
 */
const googleReady = (callback) => {
  if (initialized) {
    callback()
  }
  else {
    initCallbacks.push(callback)
  }
}

/**
 * Initializes Google SDK.
 */
export const googleInit = () => {
  if (!initialized) {

    // Set Google SDK initialization callback.
    window.googleAsyncInit = () => {

      // Load the Google Client library.
      // noinspection JSUnresolvedVariable
      gapi.load('client', function() {
        // noinspection JSUnresolvedVariable
        gapi.client
          .init({
            apiKey: GOOGLE.API_KEY,
            clientId: GOOGLE.CLIENT_ID,
            discoveryDocs: GOOGLE.DISCOVERY_DOCS,
            scope: GOOGLE.SCOPES
          })
          .then(function () {
            // Mark Google SDK as initialized.
            initialized = true

            // Run all postponed init callbacks.
            initCallbacks.forEach(callback => {
              callback()
            })
          })
      })
    }

    (function (d, s, id) {
      if (d.getElementById(id)) {
        return
      }

      const js = d.createElement(s)
      js.id = id
      js.src = "https://apis.google.com/js/api.js?onload=googleAsyncInit"
      js.setAttribute('async', 'async')
      js.setAttribute('defer', 'defer')

      const fjs = d.getElementsByTagName(s)[0]
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'google-jssdk'))
  }
}

/**
 * Reads data from a spreadsheet.
 *
 * @param {{access_token: string}} token
 *   Google access token.
 * @param {string} spreadsheetId
 *   Spreadsheet ID.
 * @param {string} range
 *   Data range.
 * @param {function} callback
 *   Callback that gets array of spreadsheet values once they are obtained.
 */
export function googleReadSheet (token, spreadsheetId, range, callback) {
  googleReady(() => {
    // noinspection JSUnresolvedVariable,JSUnresolvedFunction
    gapi.client.setToken(token)

    // noinspection JSUnresolvedVariable
    gapi.client.sheets.spreadsheets.values
      .get({
        spreadsheetId,
        range,
      })
      .then(response => {
        const result = response.result
        callback(result ? result.values : [])
      })
  })
}

/**
 * Writes data to a spreadsheet.
 *
 * @param {{access_token: string}} token
 *   Google access token.
 * @param {string} spreadsheetId
 *   Spreadsheet ID.
 * @param {string} range
 *   Data range.
 * @param {Array} values
 *   Array of values to insert.
 * @param {function} callback
 *   Callback that gets writing result.
 */
export function googleAppendSheet (token, spreadsheetId, range, values, callback) {
  googleReady(() => {
    // noinspection JSUnresolvedVariable,JSUnresolvedFunction
    gapi.client.setToken(token)

    // noinspection JSUnresolvedVariable
    gapi.client.sheets.spreadsheets.values
      .append({
        spreadsheetId,
        range,
        valueInputOption: 'USER_ENTERED',
        resource: {
          values
        }
      })
      .then(response => {
        const result = response.result
        callback(result ? result : false)
      })
  })
}

/**
 * Requests Google access token.
 *
 * @return {Promise<{access_token: string}>}
 */
export async function googleGetToken () {
  const result = await fetch(GOOGLE.APP_URL)
  const access_token = await result.text()
  return { access_token }
}

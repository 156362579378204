/**
 * @file
 * Various tools.
 */

/**
 * Rounds number to 2 decimal digits.
 *
 * @param {number} number
 *   Number to round.
 *
 * @return {number}
 */
export function round2 (number) {
  return Math.round((number + Number.EPSILON) * 100) / 100
}

import React from 'react'
import PropTypes from 'prop-types'

/**
 * Renders users select.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function UserSelect (props) {
  const { form, users, label, name, className } = props
  return (
    <div className={className}>
      <label>{label}</label>
      <select
        className={form.getInputClass(name)}
        required={true}
        name={name}
        value={form.getValue(name)}
        onChange={form.onChange}
      >
        <option value=''/>
        {users.map(m => (
          <option key={m} value={m}>{m}</option>
        ))}
      </select>
      {form.renderErrors(name)}
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
UserSelect.propTypes = {
  form: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
}

import React from 'react'
import PropTypes from 'prop-types'

/**
 * Renders textarea input.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function Textarea (props) {
  const { form, label, name, className, inputProps } = props

  return (
    <div className={className}>
      <label>{label}</label>
      <textarea
        className={form.getInputClass(name)}
        required={true}
        name={name}
        value={form.getValue(name)}
        onChange={form.onChange}
        {...inputProps}
      />
      {form.renderErrors(name)}
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
Textarea.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  inputProps: PropTypes.object
}

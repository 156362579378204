import React from 'react'
import PropTypes from 'prop-types'

/**
 * Renders select input.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function Select (props) {
  const { form, label, name, className, options } = props
  return (
    <div className={className}>
      <label>{label}</label>
      <select
        className={form.getInputClass(name)}
        required={true}
        name={name}
        value={form.getValue(name)}
        onChange={form.onChange}
      >
        <option value=''/>
        {Array.isArray(options) ? options.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        )) : Object.keys(options).map(option => (
          <option key={option} value={option}>
            {options[option]}
          </option>
        ))}
      </select>
      {form.renderErrors(name)}
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
Select.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  options: PropTypes.any.isRequired
}

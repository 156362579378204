import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { FORM_TABS, ROLES } from 'ar/constants'
import { getLeadFields } from 'ar/utils'
import {
  Checkbox,
  ClientSelect1,
  IntegerInput,
  MonthSelect,
  OverbudgetFields,
  Select
} from 'ar/components/fields'

import { NextButton } from './NextButton'

/**
 * Renders Lead form section (common for Lead Writer, Lead Editor and Lead QC roles).
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function LeadForm (props) {
  const { form, clients, users, setTab, setTabs } = props

  const role = form.getValue('role')

  let roleName = null
  switch (role) {
    case ROLES.LEAD_WRITER:
      roleName = 'lead writer'
      break
    case ROLES.LEAD_EDITOR:
      roleName = 'lead editor'
      break
    case ROLES.LEAD_QC:
      roleName = 'lead QC'
      break
  }

  const isWriter = !!form.getValue('lead_type_writer')
  const isEditor = !!form.getValue('lead_type_editor')
  const isManager = !!form.getValue('lead_type_manager')
  const isClient = !!form.getValue('lead_type_qc_client')
  const isInternal = !!form.getValue('lead_type_qc_internal')

  const writerCount = form.getValue('lead_writer_count', 0)
  const editorCount = form.getValue('lead_editor_count', 0)
  const managerCount = form.getValue('lead_manager_count', 0)
  const clientCount = form.getValue('lead_qc_client_count', 0)
  const internalCount = form.getValue('lead_qc_internal_count', 0)

  const writerRange = [...Array(writerCount).keys()]
  const editorRange = [...Array(editorCount).keys()]
  const managerRange = [...Array(managerCount).keys()]
  const clientRange = [...Array(clientCount).keys()]
  const internalRange = [...Array(internalCount).keys()]

  // List of fields on the pane.
  const fields = useMemo(function () {
    return getLeadFields(form.values)
  }, [form.values])

  /**
   * Handles "Add item" button clicks for lead writer.
   *
   * @type {function(*): void}
   */
  const onWriterAddClick = useCallback(function (e) {
    form.setValue('lead_writer_count', writerCount + 1)
    e.preventDefault()
  }, [writerCount])

  /**
   * Handles "Remove" button clicks for lead writer.
   *
   * @type {function(*): void}
   */
  const onWriterRemoveClick = useCallback(function (e) {
    if (writerCount > 1) {
      form.setValue('lead_writer_count', writerCount - 1)
    }
    e.preventDefault()
  }, [writerCount])

  /**
   * Handles "Add item" button clicks for lead editor.
   *
   * @type {function(*): void}
   */
  const onEditorAddClick = useCallback(function (e) {
    form.setValue('lead_editor_count', editorCount + 1)
    e.preventDefault()
  }, [editorCount])

  /**
   * Handles "Remove" button clicks for lead editor.
   *
   * @type {function(*): void}
   */
  const onEditorRemoveClick = useCallback(function (e) {
    if (editorCount > 1) {
      form.setValue('lead_editor_count', editorCount - 1)
    }
    e.preventDefault()
  }, [editorCount])

  /**
   * Handles "Add item" button clicks for managerial duties.
   *
   * @type {function(*): void}
   */
  const onManagerAddClick = useCallback(function (e) {
    form.setValue('lead_manager_count', managerCount + 1)
    e.preventDefault()
  }, [managerCount])

  /**
   * Handles "Remove" button clicks for managerial duties.
   *
   * @type {function(*): void}
   */
  const onManagerRemoveClick = useCallback(function (e) {
    if (managerCount > 1) {
      form.setValue('lead_manager_count', managerCount - 1)
    }
    e.preventDefault()
  }, [managerCount])

  /**
   * Handles "Add item" button clicks for client pre-publishing QC.
   *
   * @type {function(*): void}
   */
  const onClientAddClick = useCallback(function (e) {
    form.setValue('lead_qc_client_count', clientCount + 1)
    e.preventDefault()
  }, [clientCount])

  /**
   * Handles "Remove" button clicks for client pre-publishing QC.
   *
   * @type {function(*): void}
   */
  const onClientRemoveClick = useCallback(function (e) {
    if (clientCount > 1) {
      form.setValue('lead_qc_client_count', clientCount - 1)
    }
    e.preventDefault()
  }, [clientCount])

  /**
   * Handles "Add item" button clicks for internal pre-publishing QC.
   *
   * @type {function(*): void}
   */
  const onInternalAddClick = useCallback(function (e) {
    form.setValue('lead_qc_internal_count', internalCount + 1)
    e.preventDefault()
  }, [internalCount])

  /**
   * Handles "Remove" button clicks for internal pre-publishing QC.
   *
   * @type {function(*): void}
   */
  const onInternalRemoveClick = useCallback(function (e) {
    if (internalCount > 1) {
      form.setValue('lead_qc_internal_count', internalCount - 1)
    }
    e.preventDefault()
  }, [internalCount])

  /**
   * Handles "Prev" button clicks.
   *
   * @type {function(*): void}
   */
  const onPrevClick = useCallback(function (e) {
    // noinspection JSValidateTypes
    setTab(FORM_TABS.GENERAL)
    e.preventDefault()
  }, [])

  // Synchronize lead writer count with checkbox.
  useEffect(function () {
    if (isWriter) {
      if (!form.values['lead_writer_count']) {
        form.setValue('lead_writer_count', 1)
      }
    }
    else {
      form.setValue('lead_writer_count', 0)
    }
  }, [isWriter])

  // Synchronize lead editor count with checkbox.
  useEffect(function () {
    if (isEditor) {
      if (!form.values['lead_editor_count']) {
        form.setValue('lead_editor_count', 1)
      }
    }
    else {
      form.setValue('lead_editor_count', 0)
    }
  }, [isEditor])

  // Synchronize managerial duties count with checkbox.
  useEffect(function () {
    if (isManager) {
      if (!form.values['lead_manager_count']) {
        form.setValue('lead_manager_count', 1)
      }
    }
    else {
      form.setValue('lead_manager_count', 0)
    }
  }, [isManager])

  // Synchronize client pre-publishing QC count with checkbox.
  useEffect(function () {
    if (isClient) {
      if (!form.values['lead_qc_client_count']) {
        form.setValue('lead_qc_client_count', 1)
      }
    }
    else {
      form.setValue('lead_qc_client_count', 0)
    }
  }, [isClient])

  // Synchronize internal pre-publishing QC count with checkbox.
  useEffect(function () {
    if (isInternal) {
      if (!form.values['lead_qc_internal_count']) {
        form.setValue('lead_qc_internal_count', 1)
      }
    }
    else {
      form.setValue('lead_qc_internal_count', 0)
    }
  }, [isInternal])

  // noinspection JSIncompatibleTypesComparison
  return (
    <div>
      {(role === ROLES.LEAD_WRITER || role === ROLES.LEAD_QC) && (
        <Fragment>
          <Checkbox
            form={form}
            label={'Does the ' + roleName + ' have any client related written articles?'}
            name="lead_type_writer"
            className="form-group"
          />
          {isWriter && (
            <div className="mb-5">
              <h4>Client writer</h4>
              {writerRange.map(n => (
                <div key={n} className="card w-100 mb-3">
                  <div className="card-body">
                    <div className="form-row">
                      <ClientSelect1
                        form={form}
                        clients={clients}
                        label="Select client list & client package"
                        name={'lead_writer_' + n + '_name'}
                        className="form-group col-md-6"
                      />
                      <MonthSelect
                        form={form}
                        name={'lead_writer_' + n + '_month'}
                        label="Month the Article is written for"
                        className="form-group col-md-6"
                      />
                    </div>
                    <div className="form-row">
                      <IntegerInput
                        form={form}
                        name={'lead_writer_' + n + '_rate'}
                        label="Rate per Article"
                        className="form-group col-md-6"
                      />
                      <IntegerInput
                        form={form}
                        name={'lead_writer_' + n + '_qty'}
                        label="Qty of Articles written"
                        className="form-group col-md-6"
                      />
                    </div>
                    {n > 0 && n === writerCount - 1 && (
                      <button
                        type="submit"
                        className="btn btn-sm btn-danger"
                        onClick={onWriterRemoveClick}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
              <button
                type="submit"
                className="btn btn-sm btn-info"
                onClick={onWriterAddClick}
              >
                Add item
              </button>
            </div>
          )}
        </Fragment>
      )}
      {(role === ROLES.LEAD_EDITOR || role === ROLES.LEAD_QC) && (
        <Fragment>
          <Checkbox
            form={form}
            label={'Does the ' + roleName + ' have any client related edited articles?'}
            name="lead_type_editor"
            className="form-group"
          />
          {isEditor && (
            <div className="mb-5">
              <h4>Client editor</h4>
              {editorRange.map(n => (
                <div key={n} className="card w-100 mb-3">
                  <div className="card-body">
                    <div className="form-row">
                      <ClientSelect1
                        form={form}
                        clients={clients}
                        label="Select client list & client package"
                        name={'lead_editor_' + n + '_name'}
                        className="form-group col-md-6"
                      />
                      <MonthSelect
                        form={form}
                        name={'lead_editor_' + n + '_month'}
                        label="Month the Article is written for"
                        className="form-group col-md-6"
                      />
                    </div>
                    <div className="form-row">
                      <IntegerInput
                        form={form}
                        name={'lead_editor_' + n + '_rate'}
                        label="Rate per hour"
                        className="form-group col-lg-4"
                      />
                      <IntegerInput
                        form={form}
                        name={'lead_editor_' + n + '_time'}
                        label="Edit time spent in minutes on each article"
                        className="form-group col-lg-4"
                      />
                      <IntegerInput
                        form={form}
                        name={'lead_editor_' + n + '_qty'}
                        label="Qty of Articles edited"
                        className="form-group col-lg-4"
                      />
                    </div>
                    <OverbudgetFields
                      form={form}
                      prefix={'lead_editor_' + n + '_'}
                      checkboxLabel="Over 30 minutes per article?"
                      textareaLabel="Explain in detail why over time spent"
                      writers={users.writers}
                    />
                    {n > 0 && n === clientCount - 1 && (
                      <button
                        type="submit"
                        className="btn btn-sm btn-danger"
                        onClick={onEditorRemoveClick}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
              <button
                type="submit"
                className="btn btn-sm btn-info"
                onClick={onEditorAddClick}
              >
                Add item
              </button>
            </div>
          )}
        </Fragment>
      )}
      <Checkbox
        form={form}
        label={'Does the ' + roleName + ' have any managerial duties?'}
        name="lead_type_manager"
        className="form-group"
      />
      {isManager && (
        <div className="mb-5">
          <h4>Managerial Duties</h4>
          {managerRange.map(n => (
            <div key={n} className="card w-100 mb-3">
              <div className="card-body">
                <div className="form-row">
                  <Select
                    form={form}
                    name={'lead_manager_' + n + '_type'}
                    label="Type of managerial duties"
                    className="form-group col-lg-4"
                    options={getManagerialDutiesOptions(role)}
                  />
                  <IntegerInput
                    form={form}
                    name={'lead_manager_' + n + '_rate'}
                    label="Rate per hour"
                    className="form-group col-lg-4"
                  />
                  <IntegerInput
                    form={form}
                    name={'lead_manager_' + n + '_time'}
                    label="Time spent in minutes on managerial duties"
                    className="form-group col-lg-4"
                  />
                </div>
                {n > 0 && n === managerCount - 1 && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-danger"
                    onClick={onManagerRemoveClick}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
          <button
            type="submit"
            className="btn btn-sm btn-info"
            onClick={onManagerAddClick}
          >
            Add item
          </button>
        </div>
      )}
      <Checkbox
        form={form}
        label={'Does the ' + roleName + ' have any client related pre publishing QC?'}
        name="lead_type_qc_client"
        className="form-group"
      />
      {isClient && (
        <div className="mb-5">
          <h4>Pre Publishing QC Client Related Articles</h4>
          {clientRange.map(n => (
            <div key={n} className="card w-100 mb-3">
              <div className="card-body">
                <div className="form-row">
                  <ClientSelect1
                    form={form}
                    clients={clients}
                    label="Select client list & client package"
                    name={'lead_qc_client_' + n + '_name'}
                    className="form-group col-md-6"
                  />
                  <MonthSelect
                    form={form}
                    name={'lead_qc_client_' + n + '_month'}
                    label="Month the Article is written for"
                    className="form-group col-md-6"
                  />
                </div>
                <div className="form-row">
                  <IntegerInput
                    form={form}
                    name={'lead_qc_client_' + n + '_rate'}
                    label="Rate per hour"
                    className="form-group col-lg-4"
                  />
                  <IntegerInput
                    form={form}
                    name={'lead_qc_client_' + n + '_time'}
                    label="QC time spent in minutes on each article"
                    className="form-group col-lg-4"
                  />
                  <IntegerInput
                    form={form}
                    name={'lead_qc_client_' + n + '_qty'}
                    label="Qty of Articles Pre QC"
                    className="form-group col-lg-4"
                  />
                </div>
                <OverbudgetFields
                  form={form}
                  prefix={'lead_qc_client_' + n + '_'}
                  checkboxLabel="Over 5 mins per article in pre publishing? (20 mins per 8 articles)"
                  textareaLabel="Explain in detail why overbudget on time"
                  writers={users.writers}
                  editors={users.editors}
                />
                {n > 0 && n === clientCount - 1 && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-danger"
                    onClick={onClientRemoveClick}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
          <button
            type="submit"
            className="btn btn-sm btn-info"
            onClick={onClientAddClick}
          >
            Add item
          </button>
        </div>
      )}
      {role === ROLES.LEAD_QC && (
        <Fragment>
          <Checkbox
            form={form}
            label="Does the lead QC have any internal pre publishing QC?"
            name="lead_type_qc_internal"
            className="form-group"
          />
          {isInternal && (
            <div className="mb-5">
              <h4>Internal Pre Publishing QC</h4>
              {internalRange.map(n => (
                <div key={n} className="card w-100 mb-3">
                  <div className="card-body">
                    <div className="form-row">
                      <Select
                        form={form}
                        name={'lead_qc_internal_' + n + '_type'}
                        label="What type of articles were written?"
                        className="form-group col-md-6"
                        options={{
                          'Addiction Recovery': 'Addiction Recovery',
                          'Senior Care': 'Senior Care'
                        }}
                      />
                      <MonthSelect
                        form={form}
                        name={'lead_qc_internal_' + n + '_month'}
                        label="Month the Article is written for"
                        className="form-group col-md-6"
                      />
                    </div>
                    <div className="form-row">
                      <IntegerInput
                        form={form}
                        name={'lead_qc_internal_' + n + '_rate'}
                        label="Rate per hour"
                        className="form-group col-lg-4"
                      />
                      <IntegerInput
                        form={form}
                        name={'lead_qc_internal_' + n + '_time'}
                        label="QC time spent in minutes on each article"
                        className="form-group col-lg-4"
                      />
                      <IntegerInput
                        form={form}
                        name={'lead_qc_internal_' + n + '_qty'}
                        label="Qty of Articles Pre QC"
                        className="form-group col-lg-4"
                      />
                    </div>
                    <OverbudgetFields
                      form={form}
                      prefix={'lead_qc_internal_' + n + '_'}
                      checkboxLabel="Over 5 mins per article in pre publishing? (20 mins per 8 articles)"
                      textareaLabel="Explain in detail why overbudget on time"
                      writers={users.writers}
                      editors={users.editors}
                    />
                    {n > 0 && n === internalCount - 1 && (
                      <button
                        type="submit"
                        className="btn btn-sm btn-danger"
                        onClick={onInternalRemoveClick}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}
              <button
                type="submit"
                className="btn btn-sm btn-info"
                onClick={onInternalAddClick}
              >
                Add item
              </button>
            </div>
          )}
        </Fragment>
      )}
      <Checkbox
        form={form}
        label={'Does the ' + roleName + ' have any training?'}
        name="training"
        className="form-group"
      />
      <div className="d-flex justify-content-between">
        <button
          type="submit"
          className="btn btn-secondary"
          onClick={onPrevClick}
        >
          Back
        </button>
        <NextButton
          form={form}
          fields={fields}
          tab={FORM_TABS.LEAD_QC}
          setTab={setTab}
          setTabs={setTabs}
        />
      </div>
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
LeadForm.propTypes = {
  tab: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  clients: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  setTab: PropTypes.func.isRequired,
  setTabs: PropTypes.func.isRequired
}

/**
 * Returns managerial duties type options.
 *
 * @param {string} role
 *   The user role.
 * @return {Object}
 */
function getManagerialDutiesOptions (role) {
  return {
    'Writer & Editor Training': 'Writer & Editor Training',
    'Writer & Editor Coordination': 'Writer & Editor Coordination',
    'Updating training & managing documents': 'Updating training & managing documents',
    'Hiring support': 'Hiring support'
  }
}

import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { FORM_TABS } from 'ar/constants'
import { getWizardFields } from 'ar/utils'
import {
  Checkbox,
  ClientSelect2,
  IntegerInput,
  OverbudgetFields,
  Select,
  TextInput
} from 'ar/components/fields'

import { NextButton } from './NextButton'

/**
 * Renders Client Wizard form section.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function WizardForm (props) {
  const { form, clients, setTab, setTabs } = props

  const isRetention = !!form.getValue('wizard_type_retention')
  const isPrePublishing = !!form.getValue('wizard_type_pre_publishing')
  const isPostPublishing = !!form.getValue('wizard_type_post_publishing')
  const isMarketing = !!form.getValue('wizard_type_marketing')
  const isOther = !!form.getValue('wizard_type_other')

  const retentionCount = form.getValue('wizard_retention_count', 0)
  const prePublishingCount = form.getValue('wizard_pre_publishing_count', 0)
  const postPublishingCount = form.getValue('wizard_post_publishing_count', 0)
  const marketingCount = form.getValue('wizard_marketing_count', 0)
  const otherCount = form.getValue('wizard_other_count', 0)

  const retentionRange = [...Array(retentionCount).keys()]
  const prePublishingRange = [...Array(prePublishingCount).keys()]
  const postPublishingRange = [...Array(postPublishingCount).keys()]
  const marketingRange = [...Array(marketingCount).keys()]
  const otherRange = [...Array(otherCount).keys()]

  // List of fields on the pane.
  const fields = useMemo(function () {
    return getWizardFields(form.values)
  }, [form.values])

  /**
   * Handles "Add item" button clicks for "Client retention" section.
   *
   * @type {function(*): void}
   */
  const onRetentionAddClick = useCallback(function (e) {
    form.setValue('wizard_retention_count', retentionCount + 1)
    e.preventDefault()
  }, [retentionCount])

  /**
   * Handles "Remove" button clicks for "Client retention" section.
   *
   * @type {function(*): void}
   */
  const onRetentionRemoveClick = useCallback(function (e) {
    if (retentionCount > 1) {
      form.setValue('wizard_retention_count', retentionCount - 1)
    }
    e.preventDefault()
  }, [retentionCount])

  /**
   * Handles "Add item" button clicks for "Client pre publishing" section.
   *
   * @type {function(*): void}
   */
  const onPrePublishingAddClick = useCallback(function (e) {
    form.setValue('wizard_pre_publishing_count', prePublishingCount + 1)
    e.preventDefault()
  }, [prePublishingCount])

  /**
   * Handles "Remove" button clicks for "Client pre publishing" section.
   *
   * @type {function(*): void}
   */
  const onPrePublishingRemoveClick = useCallback(function (e) {
    if (prePublishingCount > 1) {
      form.setValue('wizard_pre_publishing_count', prePublishingCount - 1)
    }
    e.preventDefault()
  }, [prePublishingCount])

  /**
   * Handles "Add item" button clicks for "Client post publishing" section.
   *
   * @type {function(*): void}
   */
  const onPostPublishingAddClick = useCallback(function (e) {
    form.setValue('wizard_post_publishing_count', postPublishingCount + 1)
    e.preventDefault()
  }, [postPublishingCount])

  /**
   * Handles "Remove" button clicks for "Client post publishing" section.
   *
   * @type {function(*): void}
   */
  const onPostPublishingRemoveClick = useCallback(function (e) {
    if (postPublishingCount > 1) {
      form.setValue('wizard_post_publishing_count', postPublishingCount - 1)
    }
    e.preventDefault()
  }, [postPublishingCount])

  /**
   * Handles "Add item" button clicks for "Client marketing" section.
   *
   * @type {function(*): void}
   */
  const onMarketingAddClick = useCallback(function (e) {
    form.setValue('wizard_marketing_count', marketingCount + 1)
    e.preventDefault()
  }, [marketingCount])

  /**
   * Handles "Remove" button clicks for "Client marketing" section.
   *
   * @type {function(*): void}
   */
  const onMarketingRemoveClick = useCallback(function (e) {
    if (marketingCount > 1) {
      form.setValue('wizard_marketing_count', marketingCount - 1)
    }
    e.preventDefault()
  }, [marketingCount])

  /**
   * Handles "Add item" button clicks for "Other Services" section.
   *
   * @type {function(*): void}
   */
  const onOtherAddClick = useCallback(function (e) {
    form.setValue('wizard_other_count', otherCount + 1)
    e.preventDefault()
  }, [otherCount])

  /**
   * Handles "Remove" button clicks for "Other Services" section.
   *
   * @type {function(*): void}
   */
  const onOtherRemoveClick = useCallback(function (e) {
    if (otherCount > 1) {
      form.setValue('wizard_other_count', otherCount - 1)
    }
    e.preventDefault()
  }, [otherCount])

  /**
   * Handles "Prev" button clicks.
   *
   * @type {function(*): void}
   */
  const onPrevClick = useCallback(function (e) {
    // noinspection JSValidateTypes
    setTab(FORM_TABS.GENERAL)
    e.preventDefault()
  }, [])

  // Synchronize retention count with checkbox.
  useEffect(function () {
    if (!form.values['wizard_retention_count']) {
      form.setValue('wizard_retention_count', 1)
    }
  }, [])

  // Synchronize pre-publishing count with checkbox.
  useEffect(function () {
    if (isPrePublishing) {
      if (!form.values['wizard_pre_publishing_count']) {
        form.setValue('wizard_pre_publishing_count', 1)
      }
    }
    else {
      form.setValue('wizard_pre_publishing_count', 0)
    }
  }, [isPrePublishing])

  // Synchronize post-publishing count with checkbox.
  useEffect(function () {
    if (isPostPublishing) {
      if (!form.values['wizard_post_publishing_count']) {
        form.setValue('wizard_post_publishing_count', 1)
      }
    }
    else {
      form.setValue('wizard_post_publishing_count', 0)
    }
  }, [isPostPublishing])

  // Synchronize marketing count with checkbox.
  useEffect(function () {
    if (isMarketing) {
      if (!form.values['wizard_marketing_count']) {
        form.setValue('wizard_marketing_count', 1)
      }
    }
    else {
      form.setValue('wizard_marketing_count', 0)
    }
  }, [isMarketing])

  // Synchronize other count with checkbox.
  useEffect(function () {
    if (isOther) {
      if (!form.values['wizard_other_count']) {
        form.setValue('wizard_other_count', 1)
      }
    }
    else {
      form.setValue('wizard_other_count', 0)
    }
  }, [isOther])

  // noinspection JSUnresolvedFunction,JSUnresolvedVariable
  return (
    <div>
      <Checkbox
        form={form}
        label="Does the user have any client retention to input?"
        name="wizard_type_retention"
        className="form-group"
      />
      {isRetention && (
        <div className="mb-5">
          <h4>Client Retention</h4>
          {retentionRange.map(n => (
            <div key={n} className="card w-100 mb-3">
              <div className="card-body">
                <ClientSelect2
                  form={form}
                  clients={clients}
                  label="Select Client"
                  name={'wizard_retention_' + n + '_name'}
                  className="form-group"
                />
                <NumericFields
                  form={form}
                  type="retention"
                  n={n}
                />
                <OverbudgetFields
                  form={form}
                  prefix={'wizard_retention_' + n + '_'}
                />
                {n > 0 && n === retentionCount - 1 && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-danger"
                    onClick={onRetentionRemoveClick}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
          <button
            type="submit"
            className="btn btn-sm btn-info"
            onClick={onRetentionAddClick}
          >
            Add item
          </button>
        </div>
      )}
      <Checkbox
        form={form}
        label="Does the user have any pre publishing to input?"
        name="wizard_type_pre_publishing"
        className="form-group"
      />
      {isPrePublishing && (
        <div className="mb-5">
          <h4>Pre Publishing QC</h4>
          {prePublishingRange.map(n => (
            <div key={n} className="card w-100 mb-3">
              <div className="card-body">
                <ClientSelect2
                  form={form}
                  clients={clients}
                  label="Select Client"
                  name={'wizard_pre_publishing_' + n + '_name'}
                  className="form-group"
                />
                <NumericFields
                  form={form}
                  type="pre_publishing"
                  n={n}
                />
                <OverbudgetFields
                  form={form}
                  prefix={'wizard_pre_publishing_' + n + '_'}
                />
                {n > 0 && n === prePublishingCount - 1 && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-danger"
                    onClick={onPrePublishingRemoveClick}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
          <button
            type="submit"
            className="btn btn-sm btn-info"
            onClick={onPrePublishingAddClick}
          >
            Add item
          </button>
        </div>
      )}
      <Checkbox
        form={form}
        label="Does the user have any post publishing to input?"
        name="wizard_type_post_publishing"
        className="form-group"
      />
      {isPostPublishing && (
        <div className="mb-5">
          <h4>Post Publishing QC</h4>
          {postPublishingRange.map(n => (
            <div key={n} className="card w-100 mb-3">
              <div className="card-body">
                <ClientSelect2
                  form={form}
                  clients={clients}
                  label="Select Client"
                  name={'wizard_post_publishing_' + n + '_name'}
                  className="form-group"
                />
                <NumericFields
                  form={form}
                  type="post_publishing"
                  n={n}
                />
                <OverbudgetFields
                  form={form}
                  prefix={'wizard_post_publishing_' + n + '_'}
                />
                {n > 0 && n === postPublishingCount - 1 && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-danger"
                    onClick={onPostPublishingRemoveClick}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
          <button
            type="submit"
            className="btn btn-sm btn-info"
            onClick={onPostPublishingAddClick}
          >
            Add item
          </button>
        </div>
      )}
      <Checkbox
        form={form}
        label="Does the user have any marketing management to input?"
        name="wizard_type_marketing"
        className="form-group"
      />
      {isMarketing && (
        <div className="mb-5">
          <h4>Marketing Management</h4>
          {marketingRange.map(n => (
            <div key={n} className="card w-100 mb-3">
              <div className="card-body">
                <Select
                  form={form}
                  label="What type of Marketing services"
                  name={'wizard_marketing_' + n + '_type'}
                  className="form-group"
                  options={{
                    'Social Media Management': 'Social Media Management',
                    'Webinar': 'Webinar',
                    'Other': 'Other'
                  }}
                />
                <NumericFields
                  form={form}
                  type="marketing"
                  n={n}
                />
                <OverbudgetFields
                  form={form}
                  prefix={'wizard_marketing_' + n + '_'}
                />
                {n > 0 && n === marketingCount - 1 && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-danger"
                    onClick={onMarketingRemoveClick}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
          <button
            type="submit"
            className="btn btn-sm btn-info"
            onClick={onMarketingAddClick}
          >
            Add item
          </button>
        </div>
      )}
      <Checkbox
        form={form}
        label="Does the user have any other services to input?"
        name="wizard_type_other"
        className="form-group"
      />
      {isOther && (
        <div className="mb-5">
          <h4>Other Services</h4>
          {otherRange.map(n => (
            <div key={n} className="card w-100 mb-3">
              <div className="card-body">
                <TextInput
                  form={form}
                  name={'wizard_other_' + n + '_type'}
                  label="What type of other services"
                  className="form-group"
                />
                <NumericFields
                  form={form}
                  type="other"
                  n={n}
                />
                <OverbudgetFields
                  form={form}
                  prefix={'wizard_other_' + n + '_'}
                />
                {n > 0 && n === otherCount - 1 && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-danger"
                    onClick={onOtherRemoveClick}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
          <button
            type="submit"
            className="btn btn-sm btn-info"
            onClick={onOtherAddClick}
          >
            Add item
          </button>
        </div>
      )}
      <Checkbox
        form={form}
        label="Does the user have any training to input?"
        name="training"
        className="form-group"
      />
      <div className="d-flex justify-content-between">
        <button
          type="submit"
          className="btn btn-secondary"
          onClick={onPrevClick}
        >
          Back
        </button>
        <NextButton
          form={form}
          fields={fields}
          tab={FORM_TABS.WIZARD}
          setTab={setTab}
          setTabs={setTabs}
        />
      </div>
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
WizardForm.propTypes = {
  form: PropTypes.object.isRequired,
  clients: PropTypes.object.isRequired,
  setTab: PropTypes.func.isRequired,
  setTabs: PropTypes.func.isRequired
}

/**
 * Renders Wizard numeric fields.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function NumericFields (props) {
  const { form, type, n } = props
  const prefix = 'wizard_' + type + '_' + n + '_'
  if (type === 'pre_publishing' || type === 'post_publishing') {
    return (
      <div className="form-row">
        <IntegerInput
          form={form}
          name={prefix + 'rate'}
          label="Rate per hour"
          className="form-group col-md-4"
        />
        <IntegerInput
          form={form}
          name={prefix + 'time'}
          label="QC time spent in minutes on each article"
          className="form-group col-md-4"
        />
        <IntegerInput
          form={form}
          name={prefix + 'qty'}
          label={'Qty of Articles ' + (type === 'pre_publishing' ? 'Pre QC' : 'Post QC')}
          className="form-group col-md-4"
        />
      </div>
    )
  }
  let timeLabel = ''
  switch (type) {
    case 'retention':
      timeLabel = 'Total time spent on client retention in minutes'
      break
    case 'marketing':
      timeLabel = 'Total time spent on marketing management in minutes'
      break
    case 'other':
      timeLabel = 'Total time spent on other services in minutes'
  }
  return (
    <div className="form-row">
      <IntegerInput
        form={form}
        name={prefix + 'rate'}
        label="Rate per hour"
        className="form-group col-md-6"
      />
      <IntegerInput
        form={form}
        name={prefix + 'time'}
        label={timeLabel}
        className="form-group col-md-6"
      />
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
NumericFields.propTypes = {
  form: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  n: PropTypes.number.isRequired
}

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Checkbox } from './Checkbox'
import { Textarea } from './Textarea'
import { UserSelect } from './UserSelect'

/**
 * Renders overbudget fields.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
export function OverbudgetFields (props) {
  const { form, prefix, checkboxLabel, writers, editors } = props
  return (
    <Fragment>
      <Checkbox
        form={form}
        label={checkboxLabel}
        name={prefix + 'overbudget'}
        className="form-group"
      />
      {!!form.getValue(prefix + 'overbudget') && (
        <Fragment>
          <Textarea
            form={form}
            name={prefix + 'overbudget_info'}
            label="Explain in detail why overbudget on time"
            className="form-group"
          />
          {!!writers && !!editors ? (
            <div className="form-row">
              <UserSelect
                form={form}
                name={prefix + 'overbudget_writer'}
                label="Writer"
                className="form-group col-md-6"
                users={writers}
              />
              <UserSelect
                form={form}
                name={prefix + 'overbudget_editor'}
                label="Editor"
                className="form-group col-md-6"
                users={editors}
              />
            </div>
          ) : (
            <Fragment>
              {!!writers && (
                <UserSelect
                  form={form}
                  name={prefix + 'overbudget_writer'}
                  label="Writer"
                  className="form-group"
                  users={writers}
                />
              )}
              {!!editors && (
                <UserSelect
                  form={form}
                  name={prefix + 'overbudget_editor'}
                  label="Editor"
                  className="form-group"
                  users={editors}
                />
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
OverbudgetFields.propTypes = {
  form: PropTypes.object.isRequired,
  prefix: PropTypes.string.isRequired,
  checkboxLabel: PropTypes.string,
  writers: PropTypes.array,
  editors: PropTypes.array
}

/**
 * Component props default values.
 *
 * @type {{}}
 */
OverbudgetFields.defaultProps = {
  checkboxLabel: 'Overbudget on time spent?'
}

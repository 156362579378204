import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { FORM_TABS } from 'ar/constants'
import { getTrainingFields } from 'ar/utils'
import { Checkbox, ClientSelect2, IntegerInput } from 'ar/components/fields'

import { NextButton } from './NextButton'

/**
 * Renders Training form section.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function TrainingForm (props) {
  const { form, clients, setTab, setTabs } = props

  const role = form.getValue('role')

  const isGeneralBoarding = !!form.getValue('training_type_general_boarding')
  const isGeneralContinuing = !!form.getValue('training_type_general_continuing')
  const isClientBoarding = !!form.getValue('training_type_client_boarding')
  const isClientContinuing = !!form.getValue('training_type_client_continuing')

  const clientBoardingCount = form.getValue('training_client_boarding_count', 0)
  const clientContinuingCount = form.getValue('training_client_continuing_count', 0)

  const clientBoardingRange = [...Array(clientBoardingCount).keys()]
  const clientContinuingRange = [...Array(clientContinuingCount).keys()]

  // List of fields on the pane.
  const fields = useMemo(function () {
    return getTrainingFields(form.values)
  }, [form.values])

  /**
   * Handles "Add item" button clicks for "Client On Boarding" section.
   *
   * @type {function(*): void}
   */
  const onClientBoardingAddClick = useCallback(function (e) {
    form.setValue('training_client_boarding_count', clientBoardingCount + 1)
    e.preventDefault()
  }, [clientBoardingCount])

  /**
   * Handles "Remove" button clicks for "Client On Boarding" section.
   *
   * @type {function(*): void}
   */
  const onClientBoardingRemoveClick = useCallback(function (e) {
    if (clientBoardingCount > 1) {
      form.setValue('training_client_boarding_count', clientBoardingCount - 1)
    }
    e.preventDefault()
  }, [clientBoardingCount])

  /**
   * Handles "Add item" button clicks for "Client On Boarding" section.
   *
   * @type {function(*): void}
   */
  const onClientContinuingAddClick = useCallback(function (e) {
    form.setValue('training_client_continuing_count', clientContinuingCount + 1)
    e.preventDefault()
  }, [clientContinuingCount])

  /**
   * Handles "Remove" button clicks for "Client On Boarding" section.
   *
   * @type {function(*): void}
   */
  const onClientContinuingRemoveClick = useCallback(function (e) {
    if (clientContinuingCount > 1) {
      form.setValue('training_client_continuing_count', clientContinuingCount - 1)
    }
    e.preventDefault()
  }, [clientContinuingCount])

  /**
   * Handles "Prev" button clicks.
   *
   * @type {function(*): void}
   */
  const onPrevClick = useCallback(function (e) {
    // noinspection JSValidateTypes
    setTab(FORM_TABS[role])
    e.preventDefault()
  }, [role])

  // Synchronize client boarding count with checkbox.
  useEffect(function () {
    if (isClientBoarding) {
      if (!form.values['training_client_boarding_count']) {
        form.setValue('training_client_boarding_count', 1)
      }
    }
    else {
      form.setValue('training_client_boarding_count', 0)
    }
  }, [isClientBoarding])

  // Synchronize internal count with checkbox.
  useEffect(function () {
    if (isClientContinuing) {
      if (!form.values['training_client_continuing_count']) {
        form.setValue('training_client_continuing_count', 1)
      }
    }
    else {
      form.setValue('training_client_continuing_count', 0)
    }
  }, [isClientContinuing])

  return (
    <div>
      <div className="form-row">
        <Checkbox
          form={form}
          label="General Training On Boarding"
          name="training_type_general_boarding"
          className="form-group col-md-6"
        />
        <Checkbox
          form={form}
          label="General Continuing Training"
          name="training_type_general_continuing"
          className="form-group col-md-6"
        />
      </div>
      {isGeneralBoarding && (
        <div className="mb-5">
          <h4>General Training On Boarding</h4>
          <div className="card w-100 mb-3">
            <div className="card-body">
              <NumericFields
                form={form}
                type="general_boarding"
              />
            </div>
          </div>
        </div>
      )}
      {isGeneralContinuing && (
        <div className="mb-5">
          <h4>General Continuing Training</h4>
          <div className="card w-100 mb-3">
            <div className="card-body">
              <NumericFields
                form={form}
                type="general_continuing"
              />
            </div>
          </div>
        </div>
      )}
      <div className="form-row">
        <Checkbox
          form={form}
          label="Client Training On Boarding"
          name="training_type_client_boarding"
          className="form-group col-md-6"
        />
        <Checkbox
          form={form}
          label="Client Continuing Training"
          name="training_type_client_continuing"
          className="form-group col-md-6"
        />
      </div>
      {isClientBoarding && (
        <div className="mb-5">
          <h4>Client Training On Boarding</h4>
          {clientBoardingRange.map(n => (
            <div key={n} className="card w-100 mb-3">
              <div className="card-body">
                <ClientSelect2
                  form={form}
                  clients={clients}
                  label="Select Client"
                  name={'training_client_boarding_' + n + '_name'}
                  className="form-group"
                />
                <NumericFields
                  form={form}
                  type="client_boarding"
                  n={n}
                />
                {n > 0 && n === clientBoardingCount - 1 && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-danger"
                    onClick={onClientBoardingRemoveClick}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
          <button
            type="submit"
            className="btn btn-sm btn-info"
            onClick={onClientBoardingAddClick}
          >
            Add item
          </button>
        </div>
      )}
      {isClientContinuing && (
        <div className="mb-5">
          <h4>Client Continuing Training</h4>
          {clientContinuingRange.map(n => (
            <div key={n} className="card w-100 mb-3">
              <div className="card-body">
                <ClientSelect2
                  form={form}
                  clients={clients}
                  label="Select Client"
                  name={'training_client_continuing_' + n + '_name'}
                  className="form-group"
                />
                <NumericFields
                  form={form}
                  type="client_continuing"
                  n={n}
                />
                {n > 0 && n === clientContinuingCount - 1 && (
                  <button
                    type="submit"
                    className="btn btn-sm btn-danger"
                    onClick={onClientContinuingRemoveClick}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}
          <button
            type="submit"
            className="btn btn-sm btn-info"
            onClick={onClientContinuingAddClick}
          >
            Add item
          </button>
        </div>
      )}
      <div className="d-flex justify-content-between">
        <button
          type="submit"
          className="btn btn-secondary"
          onClick={onPrevClick}
        >
          Back
        </button>
        <NextButton
          form={form}
          fields={fields}
          tab={FORM_TABS.TRAINING}
          setTab={setTab}
          setTabs={setTabs}
        />
      </div>
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
TrainingForm.propTypes = {
  form: PropTypes.object.isRequired,
  clients: PropTypes.object.isRequired,
  setTab: PropTypes.func.isRequired,
  setTabs: PropTypes.func.isRequired
}

/**
 * Renders Training numeric fields.
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
function NumericFields (props) {
  const { form, type, n } = props
  const prefix = 'training_' + type + '_' + (n !== undefined ? n + '_' : '')
  return (
    <div className="form-row">
      <IntegerInput
        form={form}
        name={prefix + 'rate'}
        label="Rate per hour"
        className="form-group col-md-6"
      />
      <IntegerInput
        form={form}
        name={prefix + 'time'}
        label="What was the time spent in minutes?"
        className="form-group col-md-6"
      />
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
NumericFields.propTypes = {
  form: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  n: PropTypes.number
}

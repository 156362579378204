/**
 * @file
 * Form hooks.
 */

import React, { useCallback, useEffect, useState } from 'react'

import { BACKEND, DATA_SHEET, ROLES } from 'ar/constants'
import {
  googleGetToken,
  googleReadSheet,
  googleAppendSheet,
  getSubmitData, getReviewData
} from 'ar/utils'

/**
 * Hook to set and get form field values.
 *
 * @param {Object} options
 *   (optional) Form options:
 *   - initialValues: (optional) Initial form values.
 *   - validate: (optional) Callback to be fired once some field value is
 *     changed. Must return array of field errors.
 * @return {{}}
 */
export function useFormValues (options = {}) {
  const initialValues = options['initialValues'] || {}
  const validate = options['validate'] || null
  const onSubmitted = options['onSubmitted'] || null

  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState({})

  /**
   * Validates field value and updates field errors.
   *
   * @type {function(string, *=, {}): boolean}
   */
  const validateField = useCallback(function (name, value, values) {
    const errors = typeof validate === 'function' ? validate(name, value, values) : []
    setErrors(errs => {
      return {
        ...errs,
        [name]: errors
      }
    })
    return errors.length === 0
  }, [validate])

  /**
   * Sets new form field value.
   *
   * @type {function(string, *): void}
   */
  const setValue = useCallback(function (name, value) {
    setValues(values => {
      // Validate changed field value and update field errors.
      validateField(name, value, values)
      // Update field value.
      return {
        ...values,
        [name]: value
      }
    })
  }, [validateField])

  /**
   * Returns form field value.
   *
   * @type {function(string, *): (null|*)}
   */
  const getValue = useCallback(function (name, fallback = '') {
    if (typeof values[name] === 'undefined') {
      return fallback
    }
    return values[name]
  }, [values])

  /**
   * Handles form input changes.
   *
   * @type {function(*): void}
   */
  const onChange = useCallback(function (e) {
    const target = e.target
    const name = target.name
    const value = target.type === 'checkbox' ? target.checked : target.value

    setValue(name, value)
  }, [setValue])

  /**
   * Returns form field errors.
   *
   * @type {function(string): ([])}
   */
  const getErrors = useCallback(function (name) {
    if (typeof errors[name] === 'undefined') {
      return []
    }
    return errors[name]
  }, [errors])

  /**
   * Checks if field value or the whole form is valid.
   *
   * @type {function(string|string[]): (boolean)}
   */
  const isValid = useCallback(function (name = null) {
    let valid = true
    if (name) {
      const names = Array.isArray(name) ? name : [name]
      names.forEach(name => {
        if (valid && errors[name] && errors[name].length) {
          valid = false
        }
      })
    }
    else {
      Object.values(errors).forEach(errors => {
        if (valid && errors && errors.length) {
          valid = false
        }
      })
    }
    return valid
  }, [errors])

  /**
   * Validates set of fields or the whole form.
   *
   * @type {function({names?: Array, callback?: Function}?): function(*): void}
   */
  const onValidate = useCallback(function (options = {}) {
    const fields = options['fields'] || null
    const callback = options['callback'] || null

    return function (e) {
      let valid = true
      if (fields) {
        fields.forEach(name => {
          valid = validateField(name, values[name], values) && valid
        })
      }
      else {
        Object.entries(values).forEach(entry => {
          valid = validateField(entry[0], entry[1], values) && valid
        })
      }
      if (valid && typeof callback === 'function') {
        callback(values)
      }
      e.preventDefault()
    }
  }, [values, validateField])

  const [submitting, setSubmitting] = useState(false)

  /**
   * Successful form submission callback.
   *
   * @type {function(): void}
   */
  const onSubmitSuccess = useCallback(function () {
    // Stop submitting.
    setSubmitting(false)
    // Empty the form.
    setValues(initialValues)
    // Allow parent to react on submission.
    if (typeof onSubmitted === 'function') {
      onSubmitted(values)
    }
  }, [values, initialValues, onSubmitted])

  /**
   * Submits the form.
   *
   * @type {function(): void}
   */
  const submit = useCallback(function () {
    const data = getSubmitData(values)
    const review = getReviewData(values)

    console.error(data)

    // Append values.
    if (data.length > 0) {
      googleGetToken().then(token => {
        switch (values['role']) {

          case ROLES.WIZARD:
            googleAppendSheet(token, DATA_SHEET.ID2, DATA_SHEET.APPEND_RANGE2, data, onSubmitSuccess)
            break

          case ROLES.WRITER:
          case ROLES.EDITOR:
            googleAppendSheet(token, DATA_SHEET.ID1, DATA_SHEET.APPEND_RANGE1, data, onSubmitSuccess)
            break

          case ROLES.LEAD_WRITER:
          case ROLES.LEAD_EDITOR:
          case ROLES.LEAD_QC:
            googleAppendSheet(token, DATA_SHEET.ID1, DATA_SHEET.APPEND_RANGE_LEAD, data, onSubmitSuccess)
            break
        }
      })
    }

    // Send emails containing submission data.
    if (review.items.length > 0) {
      // noinspection JSIgnoredPromiseFromCall
      fetch(BACKEND.ON_SUBMIT, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          role: values['role'],
          email: values['email_alt'] || values['email'],
          items: review.items,
          total: review.total
        }),
        method: 'POST',
        credentials: 'include'
      })
    }
  }, [values, onSubmitSuccess])

  /**
   * Returns classname for a field input.
   *
   * @type {function(string, boolean?): string}
   */
  const getInputClass = useCallback(function (name, checkbox = false) {
    let className = checkbox ? 'form-check-input' : 'form-control'
    if (!isValid(name)) {
      className += ' is-invalid'
    }
    return className
  }, [isValid])

  /**
   * Renders field errors.
   *
   * @type {function(string): *}
   */
  const renderErrors = useCallback(function (name) {
    const errors = getErrors(name)
    if (errors.length) {
      return (
        <div className="invalid-feedback">
          {errors.map(error => (
            <div key={error}>{error}</div>
          ))}
        </div>
      )
    }
    return null
  }, [getErrors])

  return {
    values,
    setValue,
    getValue,
    onChange,
    getErrors,
    isValid,
    onValidate,
    submit,
    submitting,
    getInputClass,
    renderErrors
  }
}

/**
 * Obtains and returns client options from the Google spreadsheet.
 *
 * @return {{}}
 */
export function useClientOptions () {
  const [options1, setOptions1] = useState([])
  const [options2, setOptions2] = useState([])

  useEffect(function () {
    googleGetToken().then(token => {
      googleReadSheet(token, DATA_SHEET.ID1, DATA_SHEET.CLIENT_RANGE1, setOptions1)
      googleReadSheet(token, DATA_SHEET.ID1, DATA_SHEET.CLIENT_RANGE2, setOptions2)
    })
  }, [])

  return {
    options1,
    options2
  }
}

/**
 * Obtains and returns user options from the Google spreadsheet.
 *
 * @return {{}}
 */
export function useUserOptions () {
  const [writers, setWriters] = useState([])
  const [editors, setEditors] = useState([])

  useEffect(function () {
    googleGetToken().then(token => {
      googleReadSheet(token, DATA_SHEET.ID1, DATA_SHEET.USERS_RANGE, items => {
        const writers = []
        const editors = []
        items.forEach(item => {
          if (item[2].toLowerCase().includes('writer')) {
            writers.push(item[0])
          }
          if (item[2].toLowerCase().includes('editor')) {
            editors.push(item[0])
          }
        })
        setWriters(writers)
        setEditors(editors)
      })
    })
  }, [])

  return {
    writers,
    editors
  }
}

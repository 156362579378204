import React, { useCallback, useState } from 'react'

import { FORM_TAB_NAMES, FORM_TABS } from 'ar/constants'
import { useClientOptions, useFormValues, useUserOptions } from 'ar/hooks'
import {
  getEditorFields,
  getInitialValues,
  getLeadFields,
  getTrainingFields,
  getWizardFields,
  getWriterFields
} from 'ar/utils'

import { GeneralForm } from './GeneralForm'
import { WriterForm } from './WriterForm'
import { EditorForm } from './EditorForm'
import { WizardForm } from './WizardForm'
import { LeadForm } from './LeadForm'
import { TrainingForm } from './TrainingForm'
import { Review } from './Review'

/**
 * Renders main form.
 *
 * @return {*}
 * @constructor
 */
export function Form () {
  const [result, setResult] = useState(null)

  const [tab, setTab] = useState(FORM_TABS.GENERAL)
  const [tabs, setTabs] = useState([FORM_TABS.GENERAL])

  /**
   * Form successful submission callback.
   *
   * @type {function(*): void}
   */
  const onSubmitted = useCallback(function (values) {
    setResult(values['paymonth'] + ' ' + values['payday'])
    setTab(FORM_TABS.GENERAL)
  }, [])

  const onRestartClick = useCallback(function (e) {
    setResult(null)
    e.preventDefault()
  }, [])

  const form = useFormValues({ validate, onSubmitted, initialValues: getInitialValues() })
  const clients = useClientOptions()
  const users = useUserOptions()

  if (result) {
    return (
      <div
        className="alert alert-success mx-auto"
        style={{
          maxWidth: '600px'
        }}
      >
        <h5 className="alert-heading">Well done!</h5>
        <p>
          You have successfully submitted your payroll for {result}
        </p>
        <hr/>
        <a href="#" className="btn btn-success" onClick={onRestartClick}>
          Close
        </a>
      </div>
    )
  }

  return (
    <div className="card">
      <div className="card-header">
        <ul className="nav nav-tabs card-header-tabs">
          {tabs.map(t => (
            <li key={t} className="nav-item">
              <div className={'nav-link' + (t === tab ? ' active' : '')}>
                {FORM_TAB_NAMES[t]}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="card-body">
        <form onSubmit={form.onValidate()}>
          {tab === FORM_TABS.GENERAL && (
            <GeneralForm
              form={form}
              setTab={setTab}
              setTabs={setTabs}
            />
          )}
          {tab === FORM_TABS.WRITER && (
            <WriterForm
              form={form}
              clients={clients}
              setTab={setTab}
              setTabs={setTabs}
            />
          )}
          {tab === FORM_TABS.EDITOR && (
            <EditorForm
              form={form}
              clients={clients}
              users={users}
              setTab={setTab}
              setTabs={setTabs}
            />
          )}
          {tab === FORM_TABS.WIZARD && (
            <WizardForm
              form={form}
              clients={clients}
              setTab={setTab}
              setTabs={setTabs}
            />
          )}
          {(tab === FORM_TABS.LEAD_WRITER || tab === FORM_TABS.LEAD_EDITOR || tab === FORM_TABS.LEAD_QC) && (
            <LeadForm
              tab={tab}
              form={form}
              clients={clients}
              users={users}
              setTab={setTab}
              setTabs={setTabs}
            />
          )}
          {tab === FORM_TABS.TRAINING && (
            <TrainingForm
              form={form}
              clients={clients}
              setTab={setTab}
              setTabs={setTabs}
            />
          )}
          {tab === FORM_TABS.REVIEW && (
            <Review
              form={form}
              tabs={tabs}
              setTab={setTab}
            />
          )}
        </form>
      </div>
    </div>
  )
}

/**
 * Validates field values.
 *
 * @param {string} name
 *   The field name.
 * @param {*} value
 *   The value to validate.
 * @param {Object} values
 *   The form values.
 *
 * @return {[]}
 *   List of field errors.
 */
function validate (name, value, values) {
  const errors = []

  // General section.
  checkRequired(name, value, errors, [
    'name',
    'email',
    'paymonth',
    'payday',
    'role'
  ])

  // Writer section.
  checkRequired(name, value, errors, getWriterFields(values))

  // Editor section.
  checkRequired(name, value, errors, getEditorFields(values))

  // Wizard section.
  checkRequired(name, value, errors, getWizardFields(values))

  // Lead writer/editor/QC section.
  checkRequired(name, value, errors, getLeadFields(values))

  // Training section.
  checkRequired(name, value, errors, getTrainingFields(values))

  return errors
}

/**
 * Checks if value of a required field is empty.
 *
 * @param {string} name
 *   Field name.
 * @param {*} value
 *   Field value.
 * @param {array} errors
 *   List of field errors.
 * @param {string[]} names
 *   List of names of required fields.
 */
function checkRequired (name, value, errors, names) {
  if (!value) {
    for (let i = 0; i < names.length; i++) {
      if (name === names[i]) {
        errors.push('Field is required.')
        return
      }
    }
  }
}

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { getReviewData } from 'ar/utils'

/**
 * Renders Training form section.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
export function Review (props) {
  const { form, tabs, setTab } = props

  const data = getReviewData(form.values)

  /**
   * Handles "Prev" button clicks.
   *
   * @type {function(*): void}
   */
  const onPrevClick = useCallback(function (e) {
    // noinspection JSValidateTypes
    setTab(tabs[tabs.length - 2])
    e.preventDefault()
  }, [tabs])

  /**
   * Handles "Submit" button clicks.
   *
   * @type {function(*): void}
   */
  const onSubmitClick = useCallback(function (e) {
    form.submit()
    e.preventDefault()
  }, [form])

  return (
    <div>
      {data.items.map((section, index) => {
        const { label, cols, rows } = section
        return (
          <div key={index} className="mb-5">
            <h4>{label}</h4>
            <table className="table table-bordered">
              <thead>
                <tr>
                  {cols.map(header => (
                    <th key={header} scope="col">{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
              {rows.map((items, index) => (
                <tr key={index}>
                  {items.map((value, index) => (
                    <td key={index}>{value}</td>
                  ))}
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        )
      })}
      <h4 className="mb-5">Total: ${data.total}</h4>
      <div className="d-flex justify-content-between">
        <button
          type="submit"
          className="btn btn-secondary"
          onClick={onPrevClick}
        >
          Back
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={onSubmitClick}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

/**
 * Component properties.
 *
 * @type {{}}
 */
Review.propTypes = {
  form: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  setTab: PropTypes.func.isRequired
}
